/**
 * Text component generated using v0.dev
 */
import React from 'react';

import { cn } from '../../lib/utils';

export interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'body'
    | 'body-small'
    | 'caption'
    | 'muted'
    | 'label'
    | 'link';
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span' | 'label';
}

const variantStyles = {
  h1: 'text-3xl font-semibold tracking-tight',
  h2: 'text-2xl font-semibold tracking-tight',
  h3: 'text-xl font-semibold tracking-tight',
  h4: 'text-lg font-semibold tracking-tight',
  body: 'text-base leading-7',
  'body-small': 'text-sm leading-6',
  label:
    'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  muted: 'text-sm text-muted-foreground',

  caption: 'text-xs leading-5 text-muted-foreground',
  link: 'text-primary underline underline-offset-4 hover:text-primary/90',
};

export const Text = React.forwardRef<HTMLParagraphElement, TextProps>(
  ({ className, variant = 'body', as: Component = 'p', ...props }, ref) => {
    return (
      <Component
        ref={ref}
        className={cn(variantStyles[variant], className)}
        {...props}
      />
    );
  },
);

Text.displayName = 'Text';
