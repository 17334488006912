/**
 * Helper component created by Zames to apply a Grid layout
 * Technically this does nothing except specify the tailwind 'grid' class.
 * Just makes it easier to parse the component tree
 */
import * as React from 'react';

import { cn } from '../../lib/utils';

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const Grid = React.forwardRef<HTMLDivElement, GridProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div ref={ref} className={cn('grid', className)} {...props}>
        {children}
      </div>
    );
  },
);
Grid.displayName = 'Grid';

export { Grid };
