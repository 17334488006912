/**
 * Custom component created by Zames
 * This component is probably not that accessible yet, TODO for next time
 *
 * Usage:
 * <VerticalNav>
 *   <Link to={'/settings/team/team-members'}>
 *     <VerticalNavButton
 *       active={location.pathname.includes(
 *         '/settings/team/team-members',
 *       )}>
 *       Team Members
 *     </VerticalNavButton>
 *   </Link>
 * </VerticalNav>
 */
import React from 'react';

import { cn } from '../../lib/utils';
import { Button, ButtonProps } from './button';

const VerticalNav = React.forwardRef<HTMLDivElement>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('gap-1', 'flex flex-col', className)}
        {...props}
      />
    );
  },
);

VerticalNav.displayName = 'VerticalNav';

interface VerticalNavButtonProps extends Omit<ButtonProps, 'variant'> {
  isActive?: boolean;
}

const VerticalNavButton = React.forwardRef<
  HTMLButtonElement,
  VerticalNavButtonProps
>(({ className, isActive, children, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      variant="ghost"
      className={cn(
        'w-full items-center justify-start p-2',
        isActive
          ? 'text-flair hover:text-flair'
          : 'text-muted-foreground hover:text-foreground',
        className,
      )}
      {...props}>
      <span className="relative flex flex-col">
        {children}
        {/* Highlight on the left side */}
        {isActive && (
          <span className="bg-flair absolute -left-[8px] h-full w-0.5 rounded-full" />
        )}
      </span>
    </Button>
  );
});

VerticalNavButton.displayName = 'VerticalNavButton';

export { VerticalNav, VerticalNavButton };
