/**
 * Wraps <Input> with onChangeText handler
 */
import React from 'react';

import { Input } from './input';

export interface TextInputProps
  extends Omit<(typeof Input)['props'], 'onChange'> {
  onChangeText: (text: string) => void;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
}

export const TextInput = React.forwardRef<TextInputProps>(
  ({ onChangeText, ...props }, ref) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeText(event.target.value);
    };

    return <Input onChange={handleChange} ref={ref} {...props} />;
  },
);
