import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import { CountryUtil } from '../../util/country/CountryUtil';
import { MoneyUtil } from '../../util/money/MoneyUtil';
import * as Common from '../common';
import * as Rewards from '../rewards';

// TODO: REMOVE WHEN seller user profile is fully separate from sellers

// Shift into Pos settings?
export enum CashRoundingDirection {
  UP = 'UP',
  DOWN = 'DOWN',
  NEAREST = 'NEAREST',
  NONE = 'NONE',
}

export enum SellerConfigKey {
  GST_REGISTRATION_NUMBER = 'GST_REGISTRATION_NUMBER',
  CASH_ROUNDING_DIRECTION = 'CASH_ROUNDING_DIRECTION',
  CASH_ROUNDING_NEAREST_AMOUNT = 'CASH_ROUNDING_NEAREST_AMOUNT',
  IS_REWARDS_ENABLED = 'IS_REWARDS_ENABLED',
  IS_INTEGRATED_PAYMENTS_ENABLED = 'IS_INTEGRATED_PAYMENTS_ENABLED',
  IS_SELF_CHECKOUT_ENABLED = 'IS_SELF_CHECKOUT_ENABLED',
  IS_TERMINAL_PAYMENTS_ENABLED = 'IS_TERMINAL_PAYMENTS_ENABLED',
  IS_ADVANCED_TEAM_MANAGEMENT_ENABLED = 'IS_ADVANCED_TEAM_MANAGEMENT_ENABLED',
}

export const SELLER_USER_AUTH_CONSTANTS = {
  SALT_ROUNDS: 10,
  ACCESS_TOKEN_EXPIRY_DURATION: 3600,
  TOKEN_TYPE: 'bearer',
};

export type LatestSellerPrivacyPolicy = {
  file: Common.File;
};

export type Seller = z.infer<typeof Seller.schema> & {
  // Expandable
  logoImage?: Common.Image | null;
  cdsImage?: Common.Image | null;
  rewardsProgramme?: Rewards.RewardsProgramme | null;
  latestSellerPrivacyPolicy?: LatestSellerPrivacyPolicy | null;
};

export const createSellerBuyerDashboardUrl = ({
  sellerSlug,
  buyerDashboardUrl,
}: {
  sellerSlug: string;
  buyerDashboardUrl: string;
}): string => {
  return `https://${sellerSlug}.${buyerDashboardUrl}`;
};

export namespace Seller {
  export const _type = 'sellers.seller' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    name: z.string(),
    slug: z.string(),
    config: z.object({
      [SellerConfigKey.GST_REGISTRATION_NUMBER]: z
        .string()
        .nullable()
        .default(null),
      [SellerConfigKey.CASH_ROUNDING_DIRECTION]: z
        .nativeEnum(CashRoundingDirection)
        .nullable()
        .default(null),
      [SellerConfigKey.CASH_ROUNDING_NEAREST_AMOUNT]: z
        .number()
        .nullable()
        .default(null),
      [SellerConfigKey.IS_REWARDS_ENABLED]: z
        .boolean()
        .nullable()
        .default(null),
      [SellerConfigKey.IS_INTEGRATED_PAYMENTS_ENABLED]: z
        .boolean()
        .nullable()
        .default(null),
      [SellerConfigKey.IS_SELF_CHECKOUT_ENABLED]: z
        .boolean()
        .nullable()
        .default(null),
      [SellerConfigKey.IS_TERMINAL_PAYMENTS_ENABLED]: z
        .boolean()
        .nullable()
        .default(null),
      [SellerConfigKey.IS_ADVANCED_TEAM_MANAGEMENT_ENABLED]: z
        .boolean()
        .nullable()
        .default(null),
    }),
    logoImageId: z.string().nullable().default(null),
    cdsImageId: z.string().nullable().default(null),
    countryCode: z.nativeEnum(CountryUtil.CountryCode),
    defaultCurrencyCode: z.nativeEnum(MoneyUtil.CurrencyCode),
    buyerDashboardUrl: z.string().url(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (
    args: Partial<Seller> | any,
    buyerDashboardUrl: string,
  ): Seller => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.sellerId,
      buyerDashboardUrl: createSellerBuyerDashboardUrl({
        sellerSlug: args.slug,
        buyerDashboardUrl: buyerDashboardUrl,
      }),
    });
  };
}

export type SellerCreateParams = z.infer<typeof SellerCreateParams.schema>;
export namespace SellerCreateParams {
  export const schema = z.object({
    name: z.string(),
    slug: z.string(),
    config: z
      .object({
        GST_REGISTRATION_NUMBER: z.string().nullable().default(null),
        CASH_ROUNDING_DIRECTION: z
          .nativeEnum(CashRoundingDirection)
          .nullable()
          .default(null),
        CASH_ROUNDING_NEAREST_AMOUNT: z.number().nullable().default(null),
        IS_REWARDS_ENABLED: z.boolean().nullable().default(null),
        IS_INTEGRATED_PAYMENTS_ENABLED: z.boolean().nullable().default(null),
        IS_SELF_CHECKOUT_ENABLED: z.boolean().nullable().default(null),
        IS_TERMINAL_PAYMENTS_ENABLED: z.boolean().nullable().default(null),
      })
      .partial(),
    logoImageId: z.string().nullable().default(null),
    cdsImageId: z.string().nullable().default(null),
    countryCode: z.nativeEnum(CountryUtil.CountryCode),
    defaultCurrencyCode: z.nativeEnum(MoneyUtil.CurrencyCode),
  });
}

export type SellerUpdateParams = z.infer<typeof SellerUpdateParams.schema>;
export namespace SellerUpdateParams {
  export const schema = z.object({
    name: z.string().nullish(),
    slug: z.string().nullish(),
    config: z
      .object({
        GST_REGISTRATION_NUMBER: z.string().nullish(),
        CASH_ROUNDING_DIRECTION: z.nativeEnum(CashRoundingDirection).nullish(),
        CASH_ROUNDING_NEAREST_AMOUNT: z.number().nullable().nullish(),
        IS_REWARDS_ENABLED: z.boolean().nullable().nullish(),
        IS_INTEGRATED_PAYMENTS_ENABLED: z.boolean().nullish(),
        IS_SELF_CHECKOUT_ENABLED: z.boolean().nullish(),
        IS_TERMINAL_PAYMENTS_ENABLED: z.boolean().nullish(),
      })
      .nullish(),
    logoImageId: z.string().nullish(),
    cdsImageId: z.string().nullish(),
  });
}

export type Location = z.infer<typeof Location.schema>;
export namespace Location {
  export const _type = 'sellers.location' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerId: z.string().uuid(),
    name: z.string(),
    address: z.string().nullable().default(null),
    postalCode: z.string().min(5).max(6),
    phoneNumber: z.string().nullable().default(null),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    isDeleted: z.boolean().default(false),
  });

  export const create = (args: Partial<Location> | any): Location => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.sellerLocationId,
    });
  };
}

export type LocationCreateParams = z.infer<typeof LocationCreateParams.schema>;
export namespace LocationCreateParams {
  export const schema = z.object({
    name: z.string(),
    address: z.string().nullable().default(null),
    postalCode: z.string().min(5).max(6),
    phoneNumber: z.string().nullable().default(null),
  });
}

export type LocationUpdateParams = z.infer<typeof LocationUpdateParams.schema>;
export namespace LocationUpdateParams {
  export const schema = z.object({
    name: z.string().nullish().nullish(),
    address: z.string().nullish(),
    postalCode: z.string().min(5).max(6).nullish(),
    phoneNumber: z.string().nullable().nullish(),
    paymentAccountId: z.string().uuid().nullish(),
  });
}

export type SellerUser = z.infer<typeof SellerUser.schema>;
export namespace SellerUser {
  export const _type = 'sellers.user' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerUserId: z.string().uuid(),
    // TODO: This should change to 'defaultSellerId' for the seller you see when you login
    sellerId: z.string().uuid(),
    authorizedForSellerLocationIds: z.array(z.string()).default([]),
    isAuthorizedForAllSellerLocations: z.boolean().default(true),
    isSetupCompleted: z.boolean(),
    name: z.string(),
    email: z.string().nullable().default(null),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    isDeleted: z.boolean().default(false),
  });

  export const create = (args: Partial<SellerUser>): SellerUser => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.sellerUserId,
    });
  };
}

export type SellerUserCreateParams = z.infer<
  typeof SellerUserCreateParams.schema
>;
export namespace SellerUserCreateParams {
  export const schema = z.object({
    authorizedForSellerLocationIds: z.array(z.string()).default([]),
    isAuthorizedForAllSellerLocations: z.boolean().default(true),
    name: z.string(),
    email: z.string().email().nullish(),
  });
}

export type SellerUserUpdateParams = z.infer<
  typeof SellerUserUpdateParams.schema
>;
export namespace SellerUserUpdateParams {
  export const schema = z.object({
    authorizedForSellerLocationIds: z.array(z.string()).nullish(),
    isAuthorizedForAllSellerLocations: z.boolean().nullish(),
    name: z.string().nullish(),
  });
}

export type SellerUserAuth = z.infer<typeof SellerUserAuth.schema>;
export namespace SellerUserAuth {
  export const _type = 'sellers.seller_user_auth' as const;
  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    sellerUserId: z.string().uuid(),
    email: z.string(),
    passwordHash: z.string(),
    isVerified: z.boolean().default(false),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    isDeactivated: z.boolean().default(false),
  });

  export const create = (args: Partial<SellerUserAuth>): SellerUserAuth => {
    return schema.parse({
      ...args,
      _type: _type,
    });
  };
}

export type SellerAuthPayload = z.infer<typeof SellerAuthPayload.schema>;
export namespace SellerAuthPayload {
  export const schema = z.object({
    accessToken: z.string(),
    refreshToken: z.string(),
    expiresIn: z
      .literal(SELLER_USER_AUTH_CONSTANTS.ACCESS_TOKEN_EXPIRY_DURATION)
      .default(SELLER_USER_AUTH_CONSTANTS.ACCESS_TOKEN_EXPIRY_DURATION),
    tokenType: z
      .literal(SELLER_USER_AUTH_CONSTANTS.TOKEN_TYPE)
      .default(SELLER_USER_AUTH_CONSTANTS.TOKEN_TYPE),
  });

  export const create = (args: Partial<SellerAuthPayload> | any) => {
    return schema.parse({
      ...args,
      expiresIn: SELLER_USER_AUTH_CONSTANTS.ACCESS_TOKEN_EXPIRY_DURATION,
      tokenType: SELLER_USER_AUTH_CONSTANTS.TOKEN_TYPE,
    });
  };
}

export type ResetPasswordToken = z.infer<typeof ResetPasswordToken.schema>;
export namespace ResetPasswordToken {
  export const _type = 'sellers.reset_password_token' as const;
  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerUserId: z.string().uuid(),
    createdAt: z.string(),
    expiresAt: z.string(),
  });

  export const create = (
    args: Partial<ResetPasswordToken>,
  ): ResetPasswordToken => {
    return schema.parse({
      ...args,
      _type: _type,
    });
  };
}

export type SellerPrivacyPolicy = z.infer<typeof SellerPrivacyPolicy.schema>;

export namespace SellerPrivacyPolicy {
  export const _type = 'sellers.privacy_policy' as const;
  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    fileId: z.string().uuid(),
    sellerId: z.string().uuid(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (
    args: Partial<SellerPrivacyPolicy>,
  ): SellerPrivacyPolicy => {
    return schema.parse({
      ...args,
      _type: _type,
    });
  };
}
