import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import * as Common from '../common';
import * as Customers from '../customers';
import * as Listings from '../listings';
import * as Payments from '../payments';
import * as Rewards from '../rewards';
import * as Sellers from '../sellers';

export enum OrderState {
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
  VOIDED = 'VOIDED',
}

export enum OrderAppSource {
  POS = 'POS',
  SCAN_TO_ORDER = 'SCAN_TO_ORDER',
}

export enum OrderGatewaySource {
  SELLERS = 'SELLERS',
  STOREFRONT = 'STOREFRONT',
}

export enum OrderTenderType {
  CASH = 'CASH',
  CUSTOM_PAYMENT = 'CUSTOM_PAYMENT',
  INTEGRATED_PAYMENT = 'INTEGRATED_PAYMENT',
  VOUCHER = 'VOUCHER',
}

export enum OrderType {
  SALE = 'SALE',
  RETURN = 'RETURN',
}

export enum Order_FulfillmentState {
  NONE = 'NONE',
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export type AppliedDiscount = z.infer<typeof AppliedDiscount.schema>;
export namespace AppliedDiscount {
  export const schema = z.object({
    orderDiscountId: z.string().uuid(),
    amount: z.number(),
  });
}

export type AppliedExtraCharge = z.infer<typeof AppliedExtraCharge.schema>;
export namespace AppliedExtraCharge {
  export const schema = z.object({
    orderExtraChargeId: z.string().uuid(), // id of the OrderExtraCharge
    amount: z.number(),
  });
}

export type AppliedTax = z.infer<typeof AppliedTax.schema>;
export namespace AppliedTax {
  // Id of the SaleTax in this order
  export const schema = z.object({
    orderTaxId: z.string().uuid(), // id of the OrderTax
    amount: z.number(),
  });
}

// RECEIPT STUFF
// I know these models is a bit redundant for now, requires a bit of cleanup
export enum ReceiptType {
  EMAIL = 'EMAIL',
}

export type ReceiptConfig = z.infer<typeof ReceiptConfig.schema>;
export namespace ReceiptConfig {
  export const schema = z.object({
    type: z.literal(ReceiptType.EMAIL).default(ReceiptType.EMAIL),
    email: z.object({
      emailAddress: z.string(),
    }),
  });

  export const create = (params: ReceiptConfig): ReceiptConfig => {
    return schema.parse({
      ...params,
    });
  };

  export const fromEmailAddress = (emailAddress: string): ReceiptConfig => {
    return create({
      type: ReceiptType.EMAIL,
      email: {
        emailAddress: emailAddress,
      },
    });
  };
}

// =========================================================================================================

// ORDER ADD ON
export type OrderAddOn = z.infer<typeof OrderAddOn.schema>;
export namespace OrderAddOn {
  export const _type = 'orders.order_add_on' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    returnForAddOnId: z.string().uuid().nullable().default(null),
    name: z.string(),
    addOnId: z.string().uuid(),
    addOnSetName: z.string(),
    addOnSetId: z.string().uuid(),
    quantity: z.number().default(1),
    price: z.number(),
    quantityPrice: z.number().default(0),
    totalQuantity: z.number().default(0),
    totalAmount: z.number().default(0),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<OrderAddOn>): OrderAddOn => {
    return schema.parse({
      ...args,
      _type: _type,
    });
  };

  export const setQuantity = (
    orderAddOn: OrderAddOn,
    quantity: number,
  ): OrderAddOn => {
    return {
      ...orderAddOn,
      quantity: quantity,
    };
  };

  export const fromListingAddOn = ({
    listingAddOn,
    listingAddOnSet,
    overrides,
  }: {
    listingAddOn: Listings.AddOn;
    listingAddOnSet: Listings.AddOnSet;
    overrides?: Partial<OrderAddOn>;
  }): OrderAddOn => {
    return create({
      name: listingAddOn.name,
      addOnId: listingAddOn.id,
      addOnSetName: listingAddOnSet.name,
      addOnSetId: listingAddOn.addOnSetId,

      quantity: 1,
      price: listingAddOn.price,

      ...overrides,
    });
  };
}

// ORDER DISCOUNT
export type OrderDiscount = z.infer<typeof OrderDiscount.schema>;
export namespace OrderDiscount {
  export const _type = 'orders.order_discount' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    name: z.string(),
    discountId: z.string().uuid().nullable().default(null),
    rewardsDiscountId: z.string().uuid().nullable().default(null), // If rewardsDiscountId is populated, discountId will be null
    unit: z.nativeEnum(Common.ModifierUnit).default(Common.ModifierUnit.DOLLAR),
    dollarValue: z.number().default(0),
    percentValue: z.number().default(0),
    // Currently only used for Item discounts
    shouldApplyToAllAddOns: z.boolean().default(false),
    amount: z.number().default(0),
    issuedRewardId: z.string().uuid().nullable().default(null),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<OrderDiscount>): OrderDiscount => {
    return schema.parse({
      ...args,
      _type: _type,
    });
  };

  export const fromListingDiscount = ({
    listingDiscount,
    overrides,
  }: {
    listingDiscount: Listings.Discount;
    overrides?: Partial<OrderDiscount>;
  }): OrderDiscount => {
    return create({
      discountId: listingDiscount.id,
      name: listingDiscount.name,
      unit: listingDiscount.unit,
      dollarValue: listingDiscount.dollarValue,
      percentValue: listingDiscount.percentValue,
      shouldApplyToAllAddOns: listingDiscount.shouldApplyToAllAddOns,
      ...overrides,
    });
  };

  export const fromIssuedRewardDiscount = ({
    rewardsDiscount,
    issuedRewardId,
    overrides,
  }: {
    rewardsDiscount: Rewards.RewardsListingDiscount;
    issuedRewardId: string;
    overrides?: Partial<OrderDiscount>;
  }): OrderDiscount => {
    return create({
      rewardsDiscountId: rewardsDiscount.id,
      name: rewardsDiscount.name,
      unit: rewardsDiscount.discount.unit,
      dollarValue: rewardsDiscount.discount.dollarValue,
      percentValue: rewardsDiscount.discount.percentValue,
      shouldApplyToAllAddOns: rewardsDiscount.discount.shouldApplyToAllAddOns,
      issuedRewardId: issuedRewardId,

      ...overrides,
    });
  };
}

// ORDER EXTRA CHARGE
export namespace OrderExtraChargeBase {
  export const _type = 'orders.order_extra_charge' as const;
  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    name: z.string(),
    extraChargeId: z.string().uuid(),
    isLineItemPricingInclusive: z.boolean().default(false),
    unit: z.nativeEnum(Common.ModifierUnit), // TODO: Properly enforce types for dollarValue and percentValue
    dollarValue: z.number().nullable().default(null),
    percentValue: z.number().nullable().default(null),
    amount: z.number().default(0), // Calculated by Order
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });
}

export type OrderDollarExtraCharge = z.infer<
  typeof OrderDollarExtraCharge.schema
>;
export namespace OrderDollarExtraCharge {
  const _type = OrderExtraChargeBase._type;
  export const schema = OrderExtraChargeBase.schema.extend({
    unit: z
      .literal(Common.ModifierUnit.DOLLAR)
      .default(Common.ModifierUnit.DOLLAR),
    dollarValue: z.number(),
  });

  export const create = (
    args: Partial<OrderDollarExtraCharge> &
      Pick<
        OrderDollarExtraCharge,
        'name' | 'extraChargeId' | 'isLineItemPricingInclusive' | 'dollarValue'
      >,
  ): OrderDollarExtraCharge => {
    return schema.parse({
      ...args,
      _type: _type,
      unit: Common.ModifierUnit.DOLLAR,
    });
  };
}

export type OrderPercentExtraCharge = z.infer<
  typeof OrderPercentExtraCharge.schema
>;
export namespace OrderPercentExtraCharge {
  const _type = OrderExtraChargeBase._type;
  export const schema = OrderExtraChargeBase.schema.extend({
    unit: z
      .literal(Common.ModifierUnit.PERCENT)
      .default(Common.ModifierUnit.PERCENT),
    percentValue: z.number(),
  });

  export const create = (
    args: Partial<OrderPercentExtraCharge> &
      Pick<
        OrderPercentExtraCharge,
        'name' | 'extraChargeId' | 'isLineItemPricingInclusive' | 'percentValue'
      >,
  ): OrderPercentExtraCharge => {
    return schema.parse({
      ...args,
      _type: _type,
      unit: Common.ModifierUnit.PERCENT,
    });
  };
}

export type OrderExtraCharge = z.infer<typeof OrderExtraCharge.schema>;
export namespace OrderExtraCharge {
  export const schema = z.discriminatedUnion('unit', [
    OrderDollarExtraCharge.schema,
    OrderPercentExtraCharge.schema,
  ]);
  export const create = (
    args:
      | Parameters<typeof OrderDollarExtraCharge.create>[0]
      | Parameters<typeof OrderPercentExtraCharge.create>[0],
  ): OrderExtraCharge => {
    switch (args.unit) {
      case Common.ModifierUnit.DOLLAR: {
        return OrderDollarExtraCharge.create(args);
      }
      case Common.ModifierUnit.PERCENT: {
        return OrderPercentExtraCharge.create(args);
      }
      default: {
        throw new Error(
          'No `unit` field supplied to Orders.OrderExtraCharge.create()',
        );
      }
    }
  };

  export const fromListingExtraCharge = ({
    listingExtraCharge,
    overrides,
  }: {
    listingExtraCharge: Listings.ExtraCharge;
    overrides?: Parameters<typeof create>;
  }): OrderExtraCharge => {
    return create({
      extraChargeId: listingExtraCharge.id,
      name: listingExtraCharge.name,
      isLineItemPricingInclusive: listingExtraCharge.isLineItemPricingInclusive,
      unit: listingExtraCharge.unit,
      dollarValue: listingExtraCharge.dollarValue,
      percentValue: listingExtraCharge.percentValue,
      ...overrides,
    });
  };
}

// ORDER TAX
export type OrderTax = z.infer<typeof OrderTax.schema>;
export namespace OrderTax {
  export const _type = 'orders.order_tax' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    name: z.string(),
    taxId: z.string().uuid(),
    percentValue: z.number(),
    amount: z.number().default(0), // Calculated by Order
    isLineItemPricingInclusive: z.boolean(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<OrderTax>): OrderTax => {
    return schema.parse(args);
  };

  export const fromListingTax = ({
    listingTax,
    overrides,
  }: {
    listingTax: Listings.Tax;
    overrides?: Partial<OrderTax>;
  }): OrderTax => {
    return create({
      taxId: listingTax.id,
      name: listingTax.name,
      isLineItemPricingInclusive: listingTax.isLineItemPricingInclusive,
      percentValue: listingTax.percentValue,

      ...overrides,
    });
  };
}

/**
 * ORDER TENDER
 */

export namespace OrderBaseTender {
  export const _type = 'orders.order_tender' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.nativeEnum(OrderTenderType),

    name: z.string(), // eg. Cash
    amount: z.number(),
    isRefundable: z.boolean(), // -> Snapshot
    note: z.string().nullable().default(null),

    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),

    // Expandable
    // customPaymentMethod: Listings.CustomPaymentMethod.schema.optional(),
    // voucher: Listings.Voucher.schema.optional(),
    // paymentMethod: Payments.PaymentMethod.schema.optional(),
    // payment: Payments.Payment.schema.optional(),
  });
}

export type OrderCashTender = z.infer<typeof OrderCashTender.schema>;
export namespace OrderCashTender {
  export const schema = OrderBaseTender.schema.extend({
    type: z.literal(OrderTenderType.CASH).default(OrderTenderType.CASH),
    brand: z
      .literal(Common.PaymentMethodBrand.CASH)
      .default(Common.PaymentMethodBrand.CASH),
    cashDetails: z.object({
      tenderAmount: z.number(),
      changeAmount: z.number(),
      roundingAmount: z.number(),
    }),
  });

  export const create = (
    args: Pick<OrderCashTender, 'cashDetails'> & Partial<OrderCashTender>,
  ): OrderCashTender => {
    return schema.parse({
      ...args,
      type: OrderTenderType.CASH,
      name: args.name ?? 'Cash',
      amount: args.cashDetails.tenderAmount + args.cashDetails.changeAmount, // `Amount` is a calculated field. Note that all amounts are represented in terms of revenue to the Seller
      isRefundable: true,
    });
  };
}

export type OrderCustomPaymentTender = z.infer<
  typeof OrderCustomPaymentTender.schema
>;
export namespace OrderCustomPaymentTender {
  export const schema = OrderBaseTender.schema.extend({
    type: z
      .literal(OrderTenderType.CUSTOM_PAYMENT)
      .default(OrderTenderType.CUSTOM_PAYMENT),
    brand: z.nativeEnum(Common.PaymentMethodBrand).or(z.string()),
    customPaymentMethodId: z.string().uuid(), // Points to a Listing.CustomPaymentMethod
  });

  export const create = (
    args: Pick<
      OrderCustomPaymentTender,
      'name' | 'brand' | 'customPaymentMethodId' | 'amount' | 'isRefundable'
    > &
      Partial<OrderCustomPaymentTender>,
  ): OrderCustomPaymentTender => {
    return schema.parse({
      ...args,
      type: OrderTenderType.CUSTOM_PAYMENT,
    });
  };
}

export type OrderIntegratedPaymentTender = z.infer<
  typeof OrderIntegratedPaymentTender.schema
>;
export namespace OrderIntegratedPaymentTender {
  export const schema = OrderBaseTender.schema.extend({
    type: z
      .literal(OrderTenderType.INTEGRATED_PAYMENT)
      .default(OrderTenderType.INTEGRATED_PAYMENT),
    brand: z.nativeEnum(Common.PaymentMethodBrand),
    integratedPaymentDetails: z.discriminatedUnion('isRefund', [
      z.object({
        isRefund: z.literal(false).default(false),
        paymentMethodId: z.string().uuid(),
        paymentId: z.string().uuid(), // Points to a Payments.Payment
      }),
      z.object({
        isRefund: z.literal(true).default(true),
        paymentMethodId: z.string().uuid(),
        refundId: z.string().uuid(), // Points to a Payments.Refund
        refundForPaymentId: z.string().uuid(),
      }),
    ]),
  });

  export const create = (
    args: Pick<
      OrderIntegratedPaymentTender,
      'name' | 'brand' | 'amount' | 'isRefundable' | 'integratedPaymentDetails'
    > &
      Partial<OrderIntegratedPaymentTender>,
  ): OrderIntegratedPaymentTender => {
    return schema.parse({
      ...args,
      type: OrderTenderType.INTEGRATED_PAYMENT,
    });
  };

  export const fromPayment = ({
    payment,
    paymentMethod,
  }: {
    payment: Payments.Payment;
    paymentMethod: Payments.PaymentMethod;
  }): OrderIntegratedPaymentTender => {
    return create({
      name: paymentMethod.name,
      brand: paymentMethod.brand,
      amount: payment.amount,
      isRefundable: true,
      integratedPaymentDetails: {
        isRefund: false,
        paymentMethodId: paymentMethod.id,
        paymentId: payment.id,
      },
      createdAt: payment.createdAt,
      updatedAt: payment.updatedAt,
    });
  };

  // TODO: Shift to ReturnOrderIntegratedPaymentTender
  export const fromRefund = ({
    refund,
    paymentMethod,
  }: {
    refund: Payments.Refund;
    paymentMethod: Payments.PaymentMethod;
  }): OrderIntegratedPaymentTender => {
    return create({
      name: paymentMethod.name,
      brand: paymentMethod.brand,
      amount: refund.amount,
      isRefundable: false,
      integratedPaymentDetails: {
        isRefund: true,
        paymentMethodId: paymentMethod.id,
        refundId: refund.id,
        refundForPaymentId: refund.refundForPaymentId,
      },
      createdAt: refund.createdAt,
      updatedAt: refund.updatedAt,
    });
  };
}

export type OrderVoucherTender = z.infer<typeof OrderVoucherTender.schema>;
export namespace OrderVoucherTender {
  export const schema = OrderBaseTender.schema.extend({
    type: z.literal(OrderTenderType.VOUCHER).default(OrderTenderType.VOUCHER),
    brand: z
      .literal(Common.PaymentMethodBrand.VOUCHER)
      .default(Common.PaymentMethodBrand.VOUCHER),
    voucherId: z.string().uuid(), // Points to a Listing.Voucher
  });

  export const create = (
    args: Pick<OrderVoucherTender, 'name' | 'voucherId' | 'amount'> &
      Partial<OrderVoucherTender>,
  ): OrderVoucherTender => {
    return schema.parse({
      ...args,
      type: OrderTenderType.VOUCHER,
      isRefundable: false,
    });
  };
}

export type OrderTender = z.infer<typeof OrderTender.schema>;
export namespace OrderTender {
  export const _type = 'orders.order_tender' as const;

  export const schema = z.discriminatedUnion('type', [
    OrderCashTender.schema,
    OrderCustomPaymentTender.schema,
    OrderIntegratedPaymentTender.schema,
    OrderVoucherTender.schema,
  ]);

  // Use the respective OrderTenderType's create() method if you know the type beforehand
  export const create = (args: any): OrderTender => {
    switch (args.type) {
      case OrderTenderType.CASH: {
        return OrderCashTender.create(args);
      }
      case OrderTenderType.CUSTOM_PAYMENT: {
        return OrderCustomPaymentTender.create(args);
      }
      case OrderTenderType.INTEGRATED_PAYMENT: {
        return OrderIntegratedPaymentTender.create(args);
      }
      case OrderTenderType.VOUCHER: {
        return OrderVoucherTender.create(args);
      }
      default: {
        throw new Error(`No OrderTender.Type supplied`);
      }
    }
  };
}

// ORDER LINE ITEM
export type OrderLineItem = z.infer<typeof OrderLineItem.schema>;
export namespace OrderLineItem {
  export const _type = 'orders.order_line_item' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    returnForLineItemId: z.string().uuid().nullable().default(null),
    name: z.string(),
    itemId: z.string().uuid().nullable().default(null),
    rewardsItemId: z.string().uuid().nullable().default(null), // Null if itemId was supplied and vice versa
    issuedRewardId: z.string().uuid().nullable().default(null),
    itemVariationId: z.string().uuid(),
    categoryId: z.string().uuid().nullable().default(null),
    categoryName: z.string(),
    selectedItemVariationName: z.string(),
    quantity: z.number().default(1),
    sku: z.string().nullable().default(null),
    shouldTrackInventory: z.boolean().default(false),
    shouldRestock: z.boolean().default(false),
    price: z.number(),
    addOns: z.array(OrderAddOn.schema).default([]),
    discounts: z.array(OrderDiscount.schema).default([]),
    note: z.string().nullable().default(null),
    appliedDiscounts: z.array(AppliedDiscount.schema).default([]),
    appliedExtraCharges: z.array(AppliedExtraCharge.schema).default([]),
    appliedTaxes: z.array(AppliedTax.schema).default([]),

    quantityPrice: z.number().default(0),
    grossAmount: z.number().default(0),
    discountsAmount: z.number().default(0),
    extraChargesAmount: z.number().default(0),
    taxesAmount: z.number().default(0),
    totalAmount: z.number().default(0),

    metadata: z
      .object({
        acceptedAt: z.string().datetime({ offset: true }).optional(),
      })
      .default({}),

    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Record<string, unknown>): OrderLineItem => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? uuidv4(), // If no Id, generate one here
    });
  };

  export const fromListingItem = ({
    listingItem,
    listingCategory,
    listingItemVariation,
    overrides,
  }: {
    listingItem: Listings.Item;
    listingItemVariation: Listings.ItemVariation;
    listingCategory?: Listings.Category;
    overrides?: Partial<OrderLineItem>;
  }): OrderLineItem => {
    return create({
      name: listingItem.name,
      itemId: listingItem.id,
      selectedItemVariationName: listingItemVariation.name,
      itemVariationId: listingItemVariation.id,
      categoryName: listingCategory?.name ?? 'Uncategorized',
      categoryId: listingItem.categoryId,

      price: listingItemVariation.price,

      sku: listingItemVariation.sku,
      shouldTrackInventory: listingItemVariation.shouldTrackInventory,

      ...overrides,
    });
  };

  export const fromIssuedRewardItem = ({
    rewardsItem,
    issuedRewardId,
    overrides,
  }: {
    rewardsItem: Rewards.RewardsListingItem;
    issuedRewardId: string;
    overrides?: Partial<OrderLineItem>;
  }): OrderLineItem => {
    return create({
      name: rewardsItem.name,
      rewardsItemId: rewardsItem.id,
      categoryName: rewardsItem.item.categoryName ?? 'Rewards',
      categoryId: rewardsItem.item.categoryId ?? undefined,
      selectedItemVariationName:
        rewardsItem.item.itemVariationName ?? 'Default',
      itemVariationId: rewardsItem.item.itemVariationId,

      price: rewardsItem.item.price,
      quantity: 1,

      issuedRewardId: issuedRewardId,

      ...overrides,
    });
  };

  export const upsertMetadata = (
    orderLineItem: OrderLineItem,
    args: { metadata: any },
  ): OrderLineItem => {
    return {
      ...orderLineItem,
      metadata: {
        ...orderLineItem.metadata, // Cannot remove fields, only can add or set them
        ...args.metadata,
      },
    };
  };
}

export type ReturnOrderLineItem = z.infer<typeof ReturnOrderLineItem.schema>;
export namespace ReturnOrderLineItem {
  export const schema = OrderLineItem.schema.extend({
    returnForLineItemId: z.string().uuid(),
  });

  export const create = (
    args: Record<string, unknown>,
  ): ReturnOrderLineItem => {
    return schema.parse(args);
  };
}

export enum OrderFulfillmentType {
  PICKUP = 'PICKUP', // TODO: Rename to IN_STORE
  // DELIVERY = 'DELIVERY',
  // SHIPMENT = 'SHIPMENT',
}

export enum OrderFulfillmentState {
  PLACED = 'PLACED',
  ACCEPTED = 'ACCEPTED',
  PREPARED = 'PREPARED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export namespace OrderBaseFulfillment {
  export const _type = 'orders.order_fulfillment' as const;
  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    state: z
      .nativeEnum(OrderFulfillmentState)
      .default(OrderFulfillmentState.PLACED),
    isModification: z.boolean().default(false),

    shouldApplyToAllLineItems: z.boolean().default(false),
    applyToLineItemIds: z.array(z.string().uuid()).default([]),

    placedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()), // createdAt
    acceptedAt: z.string().datetime({ offset: true }).nullable().default(null),
    preparedAt: z.string().datetime({ offset: true }).nullable().default(null),
    completedAt: z.string().datetime({ offset: true }).nullable().default(null),
    failedAt: z.string().datetime({ offset: true }).nullable().default(null),
    cancelledAt: z.string().datetime({ offset: true }).nullable().default(null),
  });
}

export type OrderPickupFulfillment = z.infer<
  typeof OrderPickupFulfillment.schema
>;
export namespace OrderPickupFulfillment {
  export const _type = OrderBaseFulfillment._type;
  export const schema = OrderBaseFulfillment.schema.extend({
    type: z.literal(OrderFulfillmentType.PICKUP),
  });

  export const create = (
    args: Partial<OrderPickupFulfillment>,
  ): OrderPickupFulfillment => {
    return schema.parse({
      ...args,
      _type: _type,
      type: OrderFulfillmentType.PICKUP,
    });
  };
}

export type OrderFulfillment = z.infer<typeof OrderFulfillment.schema>;
export namespace OrderFulfillment {
  export const _type = OrderBaseFulfillment._type;
  export const schema = OrderPickupFulfillment.schema;

  export const create = (args: Partial<OrderFulfillment>): OrderFulfillment => {
    switch (args.type) {
      case OrderFulfillmentType.PICKUP: {
        return OrderPickupFulfillment.create(args);
      }
      default: {
        throw new Error(
          `[models/orders/OrderFulfillment.create] Invalid type ${args.type}, you probably want to use OrderPickupFulfillment.create() instead`,
        );
      }
    }
  };

  export const complete = (fulfillment: OrderFulfillment): OrderFulfillment => {
    // Do nothing if the fulfillment is in a terminal state
    if (isTerminalState(fulfillment)) {
      return fulfillment;
    }

    const dateTimeNowIso: string = new Date().toISOString();
    return {
      ...fulfillment,
      state: OrderFulfillmentState.COMPLETED,
      placedAt: fulfillment.placedAt,
      acceptedAt: fulfillment.acceptedAt ?? dateTimeNowIso,
      preparedAt: fulfillment.preparedAt ?? dateTimeNowIso,
      completedAt: fulfillment.completedAt ?? dateTimeNowIso,
    };
  };

  export const cancel = (fulfillment: OrderFulfillment): OrderFulfillment => {
    // Do nothing if the fulfillment is in a terminal state
    if (isTerminalState(fulfillment)) {
      return fulfillment;
    }

    return {
      ...fulfillment,
      state: OrderFulfillmentState.CANCELLED,
      cancelledAt: new Date().toISOString(),
    };
  };

  export const isTerminalState = (fulfillment: OrderFulfillment): boolean => {
    return (
      fulfillment.state === OrderFulfillmentState.COMPLETED ||
      fulfillment.state === OrderFulfillmentState.CANCELLED ||
      fulfillment.state === OrderFulfillmentState.FAILED
    );
  };
}

// BASE ORDER TYPE
// ORDER/RETURNORDER EXTENDS THE BASE ORDER TYPE
export namespace BaseOrder {
  export const _type = 'orders.order' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    sellerId: z.string().uuid(),
    locationId: z.string().uuid(),
    // The id of the first device to fulfil this order after the order is completed
    // If the order was completed on the api and fulfilled by a pos device, this will be the id of the pos device
    // Else if the order was completed and fulfilled on a pos device, this will simply be the id of the pos device
    deviceId: z.string().uuid().nullable().default(null),
    // saleNumber for this device -- Only populated if deviceId is populated
    saleNumber: z.number().nullable().default(null), // This is assigned by the server if a deviceId was passed in, and Order.status === COMPLETED
    type: z.nativeEnum(OrderType),
    // Flips to COMPLETED if there is at least 1 line item and all line items are completely paid for. This ignores whether the order has been completely fulfilled or not.
    // TODO: rename to `state`
    status: z.nativeEnum(OrderState).default(OrderState.OPEN),
    fulfillmentState: z
      .nativeEnum(Order_FulfillmentState)
      .default(Order_FulfillmentState.NONE),
    // Misc order details
    appSource: z.nativeEnum(OrderAppSource),
    source: z.nativeEnum(OrderGatewaySource), // TODO: Deprecate in favour of gatewaySource
    gatewaySource: z.nativeEnum(OrderGatewaySource),
    referenceId: z.string().default(() => Order.generateReferenceId()),
    note: z.string().nullable().default(null),

    // Customer stuff
    customerId: z.string().uuid().nullable().default(null),
    customerMapping: z // Note that customerMapping is never stored, only accepted during a POST /orders/order. TODO: Remove from model and shift it to REST layer
      .object({
        emailAddress: z.string().nullable().default(null),
        mobileNumber: z.string().nullable().default(null),
      })
      .nullable()
      .default(null),
    receiptType: z.nativeEnum(ReceiptType).nullable().default(null),

    // OrderType / Ticket
    orderTypeId: z.string().uuid().nullable().default(null),
    orderTypeName: z.string().nullable().default(null),
    ticketType: z.nativeEnum(Common.TicketType).nullable().default(null),
    ticketName: z.string().nullable().default(null),
    ticketValue: z.string().nullable().default(null),

    // Actual thingies in this Order
    lineItems: z.array(OrderLineItem.schema).default([]),
    discounts: z.array(OrderDiscount.schema).default([]),
    extraCharges: z.array(OrderExtraCharge.schema).default([]),
    taxes: z.array(OrderTax.schema).default([]),
    tenders: z.array(OrderTender.schema).default([]),

    // Fulfillments
    fulfillments: z.array(OrderFulfillment.schema).default([]),

    // Calculated Amounts
    grossAmount: z.number().default(0), // Sum of all line item gross amounts
    discountsAmount: z.number().default(0), // Sum of all OrderDiscount amounts
    extraChargesAmount: z.number().default(0), // Sum of all OrderExtraCharge amounts (inclusive & exclusive)
    taxesAmount: z.number().default(0), // Sum of all OrderTax amounts (inclusive & exclusive)
    cashRoundingAmount: z.number().default(0), // Cash rounding for this Order
    excessCollectionAmount: z.number().default(0), // Usually only populated if we collect a huge voucher and no cash change is possible, the excessCollectionAmount is counted towards the revenue for the Seller
    totalAmount: z.number().default(0), // The total amount to be collected for this Order
    subtotalAmount: z.number().default(0), // Calculated as all items + addOns + itemDiscounts
    grossSalesAmount: z.number().default(0), // Cost of all items + addOns before taxes and extra charges,  // Equal to (grossSalesAmount - any 'included' extra charges and taxes)
    dueAmount: z.number().default(0), // Amount left to pay to complete this order, calculated as (totalAmount - cash rounding) - (sum tenderAmounts)

    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    closedAt: z.string().datetime({ offset: true }).nullable().default(null), // Indicates the time that the order reaches a terminal state. This will be automatically populated in recalculateTotals()
  });
}

export type ReturnOrder = z.infer<typeof ReturnOrder.schema>;
export namespace ReturnOrder {
  export const _type = BaseOrder._type;
  export const schema = BaseOrder.schema.extend({
    type: z.literal(OrderType.RETURN),
    returnForOrderId: z.string().uuid(),
  });

  export const create = (
    args: Partial<ReturnOrder> & Pick<ReturnOrder, 'returnForOrderId'>,
  ): ReturnOrder => {
    const returnOrder: ReturnOrder = schema.parse({
      ...args,
      _type: _type,
      type: OrderType.RETURN,
      lineItems: args.lineItems ? args.lineItems.map(OrderLineItem.create) : [],
      discounts: args.discounts ? args.discounts.map(OrderDiscount.create) : [],
      extraCharges: args.extraCharges
        ? args.extraCharges.map(OrderExtraCharge.create)
        : [],
      taxes: args.taxes ? args.taxes.map(OrderTax.create) : [],
      tenders: args.tenders ? args.tenders.map(OrderTender.create) : [],
      fulfillments: args.fulfillments
        ? args.fulfillments.map(OrderFulfillment.create)
        : [],
      source: args.gatewaySource ?? args.source, // TODO: Remove migration code
    });
    return Order.makeConsistent(returnOrder);
  };

  // TODO: Split this up into
  // a. create the ReturnOrder
  // b. Slowly add line items back to the ReturnOrder
  export const fromSaleOrder = (
    originalOrder: SaleOrder,
    { overrides }: { overrides?: Partial<ReturnOrder> } = {},
  ): ReturnOrder => {
    // 1. Create a copy of the original order
    const returnOrder: ReturnOrder = ReturnOrder.create({
      id: uuidv4(), // Give the return order a new id
      sellerId: originalOrder.sellerId,
      locationId: originalOrder.locationId,
      deviceId: originalOrder.deviceId,
      status: OrderState.OPEN,
      type: OrderType.RETURN,
      appSource: originalOrder.appSource,
      source: originalOrder.gatewaySource ?? originalOrder.source, // TODO: Remove migration code
      gatewaySource: originalOrder.gatewaySource ?? originalOrder.source, // TODO: Remove migration code
      saleNumber: originalOrder.saleNumber,

      orderTypeId: originalOrder.orderTypeId,
      orderTypeName: originalOrder.orderTypeName,
      ticketType: originalOrder.ticketType,
      ticketName: originalOrder.ticketName,
      ticketValue: originalOrder.ticketValue,

      customerId: originalOrder.customerId,
      customerMapping: originalOrder.customerMapping,

      returnForOrderId: originalOrder.id,
      ...overrides,
    });

    // 2. For each OrderLineItem
    for (const originalOrderLineItem of originalOrder.lineItems) {
      // 2.1 Create a clone of each OrderLineItem
      const returnOrderLineItem: ReturnOrderLineItem =
        ReturnOrderLineItem.create({
          ...originalOrderLineItem,
          id: uuidv4(),
          returnForLineItemId: originalOrderLineItem.id,
          quantity: -1 * originalOrderLineItem.quantity,
          addOns: originalOrderLineItem.addOns.map((orderAddOn) => ({
            ...orderAddOn,
            id: uuidv4(),
            quantity: -1 * orderAddOn.quantity,
          })),
          discounts: originalOrderLineItem.discounts.map(
            (orderDiscount: OrderDiscount) => ({
              ...orderDiscount,
              id: uuidv4(),
              dollarValue: -1 * orderDiscount.dollarValue,
            }),
          ),
          appliedDiscounts: [], // Populated in recalculateTotals
          appliedExtraCharges: [], // Populated in recalculateTotals
          appliedTaxes: [], // Populated in recalculateTotals
          note: undefined,
        });
      returnOrder.lineItems.push(returnOrderLineItem);
    }

    // 4. Clone OrderDiscounts
    for (const originalOrderDiscount of originalOrder.discounts) {
      const returnOrderDiscount: OrderDiscount = OrderDiscount.create({
        ...originalOrderDiscount,
        id: uuidv4(),
        dollarValue: -1 * originalOrderDiscount.dollarValue,
      });
      returnOrder.discounts.push(returnOrderDiscount);
    }

    // 5. Clone OrderExtraCharges
    for (const originalOrderExtraCharge of originalOrder.extraCharges) {
      const returnOrderExtraCharge: OrderExtraCharge = OrderExtraCharge.create({
        ...originalOrderExtraCharge,
        id: uuidv4(),
        amount: -1 * originalOrderExtraCharge.amount,
      });
      returnOrder.extraCharges.push(returnOrderExtraCharge);
    }

    // 6. Clone OrderTaxes
    for (const originalOrderTax of originalOrder.taxes) {
      const returnOrderTax: OrderTax = OrderTax.create({
        ...originalOrderTax,
        id: uuidv4(),
        amount: -1 * originalOrderTax.amount,
      });
      returnOrder.taxes.push(returnOrderTax);
    }

    return Order.makeConsistent({
      ...returnOrder,
      ...overrides,
    });
  };
}

export type SaleOrder = z.infer<typeof SaleOrder.schema>;
export namespace SaleOrder {
  export const _type = BaseOrder._type;
  export const schema = BaseOrder.schema.extend({
    type: z.literal(OrderType.SALE),
    returns: z.array(ReturnOrder.schema),
  });

  export const create = (args: Partial<SaleOrder>): SaleOrder => {
    const order: SaleOrder = schema.parse({
      ...args,
      _type: _type,
      type: OrderType.SALE,
      lineItems: args.lineItems ? args.lineItems.map(OrderLineItem.create) : [],
      discounts: args.discounts ? args.discounts.map(OrderDiscount.create) : [],
      extraCharges: args.extraCharges
        ? args.extraCharges.map(OrderExtraCharge.create)
        : [],
      taxes: args.taxes ? args.taxes.map(OrderTax.create) : [],
      tenders: args.tenders ? args.tenders.map(OrderTender.create) : [],
      fulfillments: args.fulfillments
        ? args.fulfillments.map(OrderFulfillment.create)
        : [],
      returns: args.returns ? args.returns.map(ReturnOrder.create) : [],
      source: args.gatewaySource ?? args.source, // TODO: Remove migration code
      gatewaySource: args.gatewaySource ?? args.source, // TODO: Remove migration code
    });
    return Order.makeConsistent(order);
  };

  // Returns a value <= 0
  export const calculateReturnedAmount = (order: SaleOrder): number => {
    return _(order.returns).sumBy(
      (returnOrder: ReturnOrder) => returnOrder.totalAmount,
    );
  };

  // Returns a value <= 0
  export const calculateReturnedLineItemsQuantity = (
    order: SaleOrder,
  ): number => {
    return _(order.returns).map(Order.calculateLineItemsQuantity).sum();
  };

  /**
   * Add a ReturnOrder to for this SaleOrder
   * Currently only used on POS to updated a cached order
   */
  export const upsertReturnOrder = (
    order: SaleOrder,
    args: { returnOrder: ReturnOrder },
  ): SaleOrder => {
    // Check returnOrder.returnForOrderId
    if (args.returnOrder.returnForOrderId !== order.id) {
      return order;
    }

    let returnOrders: ReturnOrder[];
    if (
      !!order.returns.find(
        (returnOrder) => returnOrder.id === args.returnOrder.id,
      )
    ) {
      returnOrders = order.returns.map((returnOrder) => {
        if (returnOrder.id === args.returnOrder.id) {
          return args.returnOrder;
        } else {
          return returnOrder;
        }
      });
    } else {
      returnOrders = [...order.returns, args.returnOrder];
    }

    return {
      ...order,
      returns: returnOrders,
    };
  };
}

export type Order = z.infer<typeof Order.schema> & {
  customer?: Customers.Customer;
  location?: Sellers.Location;
};
export namespace Order {
  export const _type = BaseOrder._type;

  export const schema = z.discriminatedUnion('type', [
    SaleOrder.schema,
    ReturnOrder.schema,
  ]);

  export const create = (args: any): Order => {
    if (args.type === OrderType.SALE) {
      return SaleOrder.create(args);
    } else if (args.type === OrderType.RETURN) {
      return ReturnOrder.create(args);
    } else {
      // Should not happen, exit early
      throw new Error(
        `[models/orders/index.ts] Tried to initialize order with invalid type ${args.type}`,
      );
    }
  };

  export const makeConsistent = <TOrder extends Order>(
    order: TOrder,
  ): TOrder => {
    return calculateFulfillmentState(
      trySetAutomaticCustomerTicket(recalculateTotals(order)),
    );
  };

  ///////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////// ORDER METHODS //////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////

  /**
   * payment: open, fulfillment: any
   * payment: completed, fulfillment: open
   */
  export const isOngoingOrder = (order: Order): boolean => {
    return (
      order.type === OrderType.SALE && // Don't include ReturnOrders
      order.returns.length === 0 && // Don't include Orders that have been returned
      ((order.status === OrderState.COMPLETED &&
        order.fulfillmentState === Order_FulfillmentState.OPEN) ||
        (order.status === OrderState.OPEN &&
          order.gatewaySource === OrderGatewaySource.SELLERS))
    );
  };

  // Usually the change amount is negative on a normal Order (eg. Seller needs to give the Buyer money)
  // We abs() the number here for display
  export const calculateCashChangeAmountFromOrder = (order: Order): number => {
    return Math.abs(
      _(order.tenders)
        .filter(
          (orderTender: OrderTender): orderTender is OrderCashTender =>
            orderTender.type === OrderTenderType.CASH,
        )
        .sumBy(
          (orderCashTender: OrderCashTender) =>
            orderCashTender.cashDetails.changeAmount,
        ),
    );
  };

  export const upsertOrderLineItem = (
    order: Order,
    orderLineItemToUpsert: OrderLineItem,
  ): Order => {
    if (
      order.lineItems.find(
        (orderLineItem: OrderLineItem) =>
          orderLineItem.id === orderLineItemToUpsert.id,
      )
    ) {
      // Line item exists, update existing line item
      const updatedOrder: Order = {
        ...order,
        lineItems: order.lineItems.map((orderLineItem: OrderLineItem) => {
          if (orderLineItem.id === orderLineItemToUpsert.id) {
            return orderLineItemToUpsert;
          }
          return orderLineItem;
        }),
      };
      return makeConsistent(updatedOrder);
    } else {
      // Insert new line item
      const updatedOrder: Order = {
        ...order,
        lineItems: [...order.lineItems, orderLineItemToUpsert],
      };
      return makeConsistent(updatedOrder);
    }
  };

  export const removeOrderLineItemById = (
    order: Order,
    orderLineItemIdToRemove: string,
  ): Order => {
    const updatedOrder: Order = {
      ...order,
      lineItems: order.lineItems.filter(
        (orderLineItem: OrderLineItem) =>
          orderLineItem.id !== orderLineItemIdToRemove,
      ),
    };
    return makeConsistent(updatedOrder);
  };

  export const upsertOrderDiscount = (
    order: Order,
    orderDiscountToUpsert: OrderDiscount,
  ): Order => {
    if (
      order.discounts.find(
        (orderDiscount: OrderDiscount) =>
          orderDiscount.id === orderDiscountToUpsert.id,
      )
    ) {
      const updatedOrder: Order = {
        ...order,
        discounts: order.discounts.map((orderDiscount: OrderDiscount) => {
          if (orderDiscount.id === orderDiscountToUpsert.id) {
            return orderDiscountToUpsert;
          }
          return orderDiscount;
        }),
      };
      return recalculateTotals(updatedOrder);
    } else {
      const updatedOrder: Order = {
        ...order,
        discounts: [...order.discounts, orderDiscountToUpsert],
      };
      return recalculateTotals(updatedOrder);
    }
  };

  export const setOrderDiscounts = (
    order: Order,
    orderDiscounts: OrderDiscount[],
  ): Order => {
    const updatedOrder = {
      ...order,
      discounts: orderDiscounts,
    };
    return recalculateTotals(updatedOrder);
  };

  /**
   * Be careful when using this method, it may be called on COMPLETED orders that hasn't been synced to the db yet
   */
  export const removeAllTenders = (order: Order): Order => {
    if (order.status === OrderState.VOIDED) {
      throw new Error('Cannot modify a voided Order');
    }
    const updatedOrder: Order = {
      ...order,
      status: OrderState.OPEN, // Manually flip the state back to OPEN
      tenders: [],
    };
    return recalculateTotals(updatedOrder);
  };

  export const setNote = (order: Order, note: string | null): Order => {
    const updatedOrder: Order = {
      ...order,
      note: note,
    };
    return updatedOrder;
  };

  export const setReceipt = (order: Order, receipt: ReceiptConfig): Order => {
    const updatedOrder: Order = {
      ...order,
      receiptType: receipt.type,
      customerMapping: {
        emailAddress: receipt.email?.emailAddress ?? null,
        mobileNumber: null,
      },
    };
    return updatedOrder;
  };

  export const setCustomer = (
    order: Order,
    customer: Customers.Customer,
  ): Order => {
    const updatedOrder: Order = {
      ...order,
      customerId: customer.id,
    };
    return updatedOrder;
  };

  export const removeCustomer = (order: Order): Order => {
    const updatedOrder: Order = {
      ...order,
      customerId: null,
      customerMapping: null,
    };
    return updatedOrder;
  };

  export const setOrderType = <TOrder extends Order>(
    order: TOrder,
    args: {
      orderTypeId: string;
      orderTypeName: string;
      ticketType: string;
      ticketName: string;

      extraCharges: OrderExtraCharge[];
      taxes: OrderTax[];
    },
  ): TOrder => {
    const updatedOrder: TOrder = {
      ...order,
      orderTypeId: args.orderTypeId,
      orderTypeName: args.orderTypeName,
      ticketType: args.ticketType,
      ticketName: args.ticketName,
      extraCharges: args.extraCharges,
      taxes: args.taxes,
    };

    return makeConsistent(updatedOrder);
  };

  export const setTicketValue = <TOrder extends Order>(
    order: TOrder,
    args: {
      ticketValue: string | null;
    },
  ): TOrder => {
    // Invalid ticket value if there is no ticket type
    if (!order.ticketType) {
      return order;
    }
    return {
      ...order,
      ticketValue: args.ticketValue,
    };
  };

  export const setTicketValueToNullIfAutoGenerated = <TOrder extends Order>(
    order: TOrder,
  ): TOrder => {
    if (order.ticketType !== Common.TicketType.TEXT) {
      return {
        ...order,
        ticketValue: null,
      };
    } else {
      return order;
    }
  };

  export const setSaleNumber = <TOrder extends Order>(
    order: TOrder,
    { saleNumber }: { saleNumber: number },
  ): TOrder => {
    const updatedOrder: TOrder = {
      ...order,
      saleNumber: saleNumber,
    };
    return trySetAutomaticCustomerTicket(updatedOrder); // Call this since setting the saleNumber might have altered the ticketValue
  };

  export const setDevice = <TOrder extends Order>(
    order: TOrder,
    { deviceId }: { deviceId: string },
  ): TOrder => {
    const updatedOrder: TOrder = {
      ...order,
      deviceId: deviceId,
    };
    return updatedOrder;
  };

  // Add an orderTender to order
  export const upsertOrderTender = <TOrder extends Order>(
    order: TOrder,
    orderTenderToUpsert: OrderTender,
  ): TOrder => {
    let updatedOrder: TOrder;
    if (
      order.tenders.find(
        (orderTender: OrderTender) => orderTender.id === orderTenderToUpsert.id,
      )
    ) {
      updatedOrder = {
        ...order,
        tenders: order.tenders.map((orderTender: OrderTender) => {
          if (orderTender.id === orderTenderToUpsert.id) {
            return orderTenderToUpsert;
          }
          return orderTender;
        }),
      };
    } else {
      updatedOrder = {
        ...order,
        tenders: [...order.tenders, orderTenderToUpsert],
      };
    }

    return recalculateTotals(updatedOrder); // Automatically completes the order if all preconditions are met
  };

  /**
   * Note: If this fulfillment is applied to a lineItem that already has a fulfillment applied to it, this does nothing
   */
  export const upsertOrderFulfillment = <TOrder extends Order>(
    order: TOrder,
    {
      orderFulfillment: orderFulfillmentToUpsert,
    }: {
      orderFulfillment: OrderFulfillment;
    },
  ): TOrder => {
    // 2. Actually upsert orderFulfillmentToUpsert
    let updatedOrder: TOrder;
    if (
      order.fulfillments.find(
        (orderFulfillment: OrderFulfillment) =>
          orderFulfillment.id === orderFulfillmentToUpsert.id,
      )
    ) {
      // 2a. OrderFulfillment exists, update existing OrderFulfillment
      updatedOrder = {
        ...order,
        fulfillments: order.fulfillments.map(
          (orderFulfillment: OrderFulfillment) => {
            if (orderFulfillment.id === orderFulfillmentToUpsert.id) {
              return orderFulfillmentToUpsert;
            }
            return orderFulfillment;
          },
        ),
      };
    } else {
      // 2b. Insert new OrderFulfillment
      updatedOrder = {
        ...order,
        fulfillments: [...order.fulfillments, orderFulfillmentToUpsert],
      };
    }

    return calculateFulfillmentState(updatedOrder);
  };

  export const voidOrder = <TOrder extends Order>(order: TOrder): TOrder => {
    let updatedOrder: TOrder = order;

    if (!isTerminalState(order)) {
      updatedOrder = {
        ...order,
        status: OrderState.VOIDED,
        updatedAt: new Date().toISOString(),
        closedAt: new Date().toISOString(),
      };
    }

    // Cancel fulfillments
    updatedOrder = Order.cancelOrderFulfillments(updatedOrder);

    return updatedOrder;
  };

  export const cancelOrderFulfillments = <TOrder extends Order>(
    order: TOrder,
  ): TOrder => {
    // Can only cancel OrderFulfillments if it Order.fulfillmentState === OPEN
    if (order.fulfillmentState !== Order_FulfillmentState.OPEN) {
      return order;
    }

    const updatedOrder: TOrder = {
      ...order,
      fulfillmentState: Order_FulfillmentState.CANCELLED,
      fulfillments: order.fulfillments.map(OrderFulfillment.cancel),
    };

    return updatedOrder;
  };

  /**
   * PRIVATE METHODS
   */
  const recalculateTotals = <TOrder extends Order>(order: TOrder): TOrder => {
    // Copy fields from the original sale so that we don't modify the original sale
    let orderLineItems: OrderLineItem[] = _.cloneDeep(order.lineItems).map(
      (orderLineItem: OrderLineItem) => ({
        ...orderLineItem,
        // Reset applied amounts
        appliedDiscounts: [],
        appliedExtraCharges: [],
        appliedTaxes: [],
      }),
    );
    let orderDiscounts: OrderDiscount[] = _.cloneDeep(order.discounts);
    let orderExtraCharges: OrderExtraCharge[] = _.cloneDeep(order.extraCharges);
    let orderTaxes: OrderTax[] = _.cloneDeep(order.taxes);
    let orderTenders: OrderTender[] = _.cloneDeep(order.tenders);
    ////////
    let orderGrossAmount: number = 0;
    let totalLineItemDiscounts: number = 0;
    let orderDiscountsAmount: number = 0;
    let orderExtraChargesAmount: number = 0;
    let orderTaxesAmount: number = 0;
    let orderCashRoundingAmount: number = 0;
    let orderTotalAmount: number = 0;
    let orderSubtotalAmount: number = 0;
    let orderGrossSalesAmount: number = 0;
    let orderExcessCollectionAmount: number = 0;
    let orderDueAmount: number = 0;

    // 1. Line items + addons (Gross amounts) + discounts
    // 1.1 Calculate LineItem gross amounts
    orderLineItems = orderLineItems.map((orderLineItem: OrderLineItem) => {
      const itemAddOns: OrderAddOn[] = orderLineItem.addOns.map(
        (orderAddOn: OrderAddOn) => {
          const quantityPrice: number = orderAddOn.quantity * orderAddOn.price;
          const totalQuantity: number =
            Math.abs(orderLineItem.quantity) * orderAddOn.quantity;
          const totalAmount: number =
            Math.abs(orderLineItem.quantity) * quantityPrice;
          return {
            ...orderAddOn,
            quantityPrice: quantityPrice,
            totalQuantity: totalQuantity,
            totalAmount: totalAmount,
          };
        },
      );

      const quantityPrice: number =
        orderLineItem.quantity * orderLineItem.price;
      const grossAmount: number =
        quantityPrice +
        _.sumBy(itemAddOns, (orderAddOn: OrderAddOn) => orderAddOn.totalAmount);

      let itemDiscounts: OrderDiscount[] = _.cloneDeep(orderLineItem.discounts);
      itemDiscounts.forEach((orderDiscount: OrderDiscount, index: number) => {
        let discountAmount: number = 0;
        const itemAmount: number = quantityPrice;
        const addOnsAmount: number = orderDiscount.shouldApplyToAllAddOns
          ? _(itemAddOns).sumBy(
              (orderAddOn: OrderAddOn) => orderAddOn.totalAmount,
            )
          : 0;
        const discountsAmountSoFar: number = _(itemDiscounts)
          // Only consider discounts applied before this discount that are applied to the same line item
          .filter((x: OrderDiscount, innerIndex: number) => innerIndex < index)
          .sumBy((x: OrderDiscount) => x.amount);
        const lineItemAmountToApplyTo: number =
          itemAmount + addOnsAmount > 0
            ? Math.max(itemAmount + addOnsAmount + discountsAmountSoFar, 0)
            : Math.min(itemAmount + addOnsAmount + discountsAmountSoFar, 0);

        if (orderDiscount.unit === Common.ModifierUnit.DOLLAR) {
          const quantityDiscountAmount =
            Math.abs(orderLineItem.quantity) * orderDiscount.dollarValue;
          // Discount amount cannot exceed the line item amount to apply to
          discountAmount =
            -1 *
            _.clamp(
              quantityDiscountAmount,
              -1 * Math.abs(lineItemAmountToApplyTo),
              Math.abs(lineItemAmountToApplyTo),
            );
        } else if (orderDiscount.unit === Common.ModifierUnit.PERCENT) {
          discountAmount =
            -1 * round(orderDiscount.percentValue * lineItemAmountToApplyTo);
        }

        itemDiscounts = itemDiscounts.map((x: OrderDiscount) => {
          if (x.id !== orderDiscount.id) {
            return x;
          }
          return {
            ...x,
            amount: discountAmount,
          };
        });
      });

      return {
        ...orderLineItem,
        addOns: itemAddOns,
        discounts: itemDiscounts,
        quantityPrice: quantityPrice,
        grossAmount: grossAmount,
      };
    });

    // 1.2 Order.grossAmount
    orderGrossAmount = _.sumBy(
      orderLineItems,
      (orderLineItem: OrderLineItem) => orderLineItem.grossAmount,
    );
    totalLineItemDiscounts = _(orderLineItems)
      .flatMap((orderLineItem: OrderLineItem) => orderLineItem.discounts)
      .sumBy((orderDiscount: OrderDiscount) => orderDiscount.amount);

    // 2. (Bill) Discounts
    orderDiscounts.forEach((orderDiscount: OrderDiscount, index: number) => {
      let discountAmount: number = 0;
      const orderAmountToApplyTo: number =
        orderGrossAmount +
        totalLineItemDiscounts +
        _(orderDiscounts)
          // Only bill discounts applied before this discount
          .filter((x: OrderDiscount, innerIndex: number) => innerIndex < index)
          .sumBy((x: OrderDiscount) => x.amount);
      if (orderDiscount.unit === Common.ModifierUnit.DOLLAR) {
        // Discount amount shouldn't exceed the total sale amount
        discountAmount =
          -1 *
          _.clamp(
            orderDiscount.dollarValue,
            -1 * Math.abs(orderAmountToApplyTo),
            Math.abs(orderAmountToApplyTo),
          );
      } else if (orderDiscount.unit === Common.ModifierUnit.PERCENT) {
        discountAmount =
          -1 * round(orderDiscount.percentValue * orderAmountToApplyTo);
      }
      orderDiscounts = orderDiscounts.map((x: OrderDiscount) => {
        if (x.id !== orderDiscount.id) {
          return x;
        }
        return {
          ...x,
          amount: discountAmount,
        };
      });
    });

    // 2.1 Apply item discounts to orderLineItem
    orderLineItems = orderLineItems.map((orderLineItem: OrderLineItem) => {
      return {
        ...orderLineItem,
        appliedDiscounts: orderLineItem.discounts.map(
          (orderDiscount: OrderDiscount): AppliedDiscount => ({
            orderDiscountId: orderDiscount.id,
            amount: orderDiscount.amount,
          }),
        ),
      };
    });
    // 2.2 Apply bill discounts to saleItems
    orderDiscounts.forEach((orderDiscount: OrderDiscount) => {
      let appliedDiscountsAmountSoFar: number = 0;
      orderLineItems.forEach(
        (orderItemToApplyTo: OrderLineItem, index: number) => {
          let appliedAmount: number = 0;
          if (index === orderLineItems.length - 1) {
            // If this is the final line item to apply to (or if this is the only line item to apply to)
            appliedAmount = orderDiscount.amount - appliedDiscountsAmountSoFar;
          } else {
            appliedAmount = round(
              orderDiscount.amount *
                (orderItemToApplyTo.grossAmount / (orderGrossAmount || 1)), // Min 1, to prevent divide by 0 errors
            );
            appliedDiscountsAmountSoFar += appliedAmount;
          }
          const appliedDiscount: AppliedDiscount = {
            orderDiscountId: orderDiscount.id,
            amount: appliedAmount,
          };
          orderLineItems = orderLineItems.map(
            (orderLineItem: OrderLineItem) => {
              if (orderLineItem.id !== orderItemToApplyTo.id) {
                return orderLineItem;
              }
              return {
                ...orderLineItem,
                appliedDiscounts: [
                  ...orderLineItem.appliedDiscounts,
                  appliedDiscount,
                ],
              };
            },
          );
        },
      );
    });
    // 2.3 OrderLineItem.discountsAmount
    orderLineItems = orderLineItems.map((orderLineItem: OrderLineItem) => ({
      ...orderLineItem,
      discountsAmount: _.sumBy(
        orderLineItem.appliedDiscounts,
        (appliedDiscount: AppliedDiscount) => appliedDiscount.amount,
      ),
    }));
    // 2.4 Order.discountsAmount
    orderDiscountsAmount = _.sumBy(
      orderLineItems,
      (orderLineItem: OrderLineItem) => orderLineItem.discountsAmount,
    );

    // 3. Extra Charges & Taxes
    // We need to calculate extra charges and taxes together because different permutations of excluded / included pricing can cause the calculated values to change

    // 3.tax.1 Calculate (inclusive) tax amounts
    orderTaxes = orderTaxes.map((orderTax: OrderTax) => {
      // Calculate for inclusive tax first
      if (!orderTax.isLineItemPricingInclusive) {
        return {
          ...orderTax,
          amount: 0,
        };
      }
      // TODO: Filter here when we can apply taxes here to specific items / discounts / extra charges
      const orderTotal = orderGrossAmount + orderDiscountsAmount;
      // Inclusive extra charge calculation
      // Using the power of algebra: grossSalesAmount + (saleTax.percentValue * grossSalesAmount) = saleTotal --> grossAmount is unknown
      const taxAmount: number = round(
        (orderTax.percentValue / (1 + orderTax.percentValue)) * orderTotal,
      );
      return {
        ...orderTax,
        amount: taxAmount,
      };
    });

    // 3.extraCharge.1 Calculate (inclusive) extra charge amounts
    orderExtraCharges = orderExtraCharges.map(
      (orderExtraCharge: OrderExtraCharge) => {
        // Calculate for inclusive tax first
        if (!orderExtraCharge.isLineItemPricingInclusive) {
          return {
            ...orderExtraCharge,
            amount: 0,
          };
        }

        let taxAmount: number = 0;
        // TODO: Filter here when we can apply taxes here to specific items / discounts / extra charges
        const inclusiveOrderTaxesAmount: number = _(orderTaxes)
          .filter((orderTax: OrderTax) => orderTax.isLineItemPricingInclusive)
          .sumBy((orderTax: OrderTax) => orderTax.amount);
        const orderTotal =
          orderGrossAmount + orderDiscountsAmount - inclusiveOrderTaxesAmount;

        if (orderExtraCharge.unit === Common.ModifierUnit.DOLLAR) {
          taxAmount = Math.max(orderExtraCharge.dollarValue, orderTotal);
        } else if (orderExtraCharge.unit === Common.ModifierUnit.PERCENT) {
          // Inclusive extra charge calculation
          // Using the power of algebra: grossSalesAmount + (saleTax.percentValue * grossSalesAmount) = saleTotal --> grossAmount is unknown
          taxAmount = round(
            (orderExtraCharge.percentValue /
              (1 + orderExtraCharge.percentValue)) *
              orderTotal,
          );
        }

        return {
          ...orderExtraCharge,
          amount: taxAmount,
        };
      },
    );

    // 3.extraCharge.2 Calculate (exclusive) extra charge amounts
    orderExtraCharges = orderExtraCharges.map(
      (orderExtraCharge: OrderExtraCharge) => {
        // Calculate for exclusive pricing extra charge
        if (orderExtraCharge.isLineItemPricingInclusive) {
          return orderExtraCharge;
        }
        let amount = 0;
        if (orderExtraCharge.unit === Common.ModifierUnit.DOLLAR) {
          amount = orderExtraCharge.dollarValue;
        } else if (orderExtraCharge.unit === Common.ModifierUnit.PERCENT) {
          // TODO: Future, filter here when we can apply extra charge to specific line items / discounts
          const orderAmountToApplyTo =
            orderGrossAmount + orderDiscountsAmount + orderExtraChargesAmount;
          // Exclusive extra charge calculation
          amount = round(orderAmountToApplyTo * orderExtraCharge.percentValue);
        }
        return {
          ...orderExtraCharge,
          amount: amount,
        };
      },
    );

    // 3.tax.2 Calculate (exclusive) tax amounts
    orderTaxes = orderTaxes.map((orderTax: OrderTax) => {
      // Calculate for exclusive pricing tax
      if (orderTax.isLineItemPricingInclusive) {
        return orderTax;
      }
      // TODO: Filter here when we can apply taxes here to specific items / discounts / extra charges
      const exclusiveOrderExtraChargesAmount: number = _(orderExtraCharges)
        .filter(
          (orderExtraCharge: OrderExtraCharge) =>
            !orderExtraCharge.isLineItemPricingInclusive,
        )
        .sumBy((orderExtraCharge: OrderExtraCharge) => orderExtraCharge.amount);
      const orderAmountToApplyTo =
        orderGrossAmount +
        orderDiscountsAmount +
        exclusiveOrderExtraChargesAmount;
      let taxAmount: number = 0;
      taxAmount = round(orderAmountToApplyTo * orderTax.percentValue);
      return {
        ...orderTax,
        amount: taxAmount,
      };
    });

    // 3.extraCharge.3 Apply extra charge amounts to individual line items
    orderExtraCharges.forEach((orderExtraCharge: OrderExtraCharge) => {
      const orderItemsToApplyTo: OrderLineItem[] = orderLineItems; // TODO: Filter here
      let appliedExtraChargeAmountSoFar: number = 0;
      orderItemsToApplyTo.forEach(
        (orderItemToApplyTo: OrderLineItem, index: number) => {
          let appliedAmount: number = 0;
          if (index === orderItemsToApplyTo.length - 1) {
            // This is the last item to apply this extra charge to
            appliedAmount =
              orderExtraCharge.amount - appliedExtraChargeAmountSoFar;
          } else {
            const lineItemAmountToApplyTo: number =
              orderItemToApplyTo.grossAmount +
              orderItemToApplyTo.discountsAmount;
            const orderAmountToApplyTo: number =
              orderGrossAmount + orderDiscountsAmount;
            appliedAmount = round(
              // Prevent divide by zero error
              (lineItemAmountToApplyTo / (orderAmountToApplyTo || 1)) *
                orderExtraCharge.amount,
            );
            appliedExtraChargeAmountSoFar += appliedAmount;
          }

          const appliedExtraCharge: AppliedExtraCharge = {
            orderExtraChargeId: orderExtraCharge.id,
            amount: appliedAmount,
          };
          orderLineItems = orderLineItems.map(
            (orderLineItem: OrderLineItem) => {
              if (orderLineItem.id !== orderItemToApplyTo.id) {
                return orderLineItem;
              }
              return {
                ...orderLineItem,
                appliedExtraCharges: [
                  ...orderLineItem.appliedExtraCharges,
                  appliedExtraCharge,
                ],
              };
            },
          );
        },
      );
    });

    // 3.4 OrderLineItem.extraChargesAmount
    orderLineItems = orderLineItems.map((orderLineItem: OrderLineItem) => ({
      ...orderLineItem,
      extraChargesAmount: _.sumBy(
        orderLineItem.appliedExtraCharges,
        (appliedExtraCharge: AppliedExtraCharge) => appliedExtraCharge.amount,
      ),
    }));

    // 3.3 Apply taxes amounts to individual line items
    orderTaxes.forEach((orderTax: OrderTax) => {
      const orderItemsToApplyTo: OrderLineItem[] = orderLineItems; // TODO: Filter here
      let appliedTaxAmountSoFar: number = 0;
      orderItemsToApplyTo.forEach(
        (orderItemToApplyTo: OrderLineItem, index: number) => {
          let appliedAmount: number = 0;
          if (index === orderItemsToApplyTo.length - 1) {
            // This is the last item to apply this tax to
            appliedAmount = orderTax.amount - appliedTaxAmountSoFar;
          } else {
            const lineItemAmountToApplyTo: number =
              orderItemToApplyTo.grossAmount +
              orderItemToApplyTo.discountsAmount +
              orderItemToApplyTo.extraChargesAmount;
            const orderAmountToApplyTo: number =
              orderGrossAmount + orderDiscountsAmount;
            appliedAmount = round(
              // Min 1, to prevent divide by zero error
              (lineItemAmountToApplyTo / (orderAmountToApplyTo || 1)) *
                orderTax.amount,
            );
            appliedTaxAmountSoFar += appliedAmount;
          }

          const appliedTax: AppliedTax = {
            orderTaxId: orderTax.id,
            amount: appliedAmount,
          };

          orderLineItems = orderLineItems.map(
            (orderLineItem: OrderLineItem) => {
              if (orderLineItem.id !== orderItemToApplyTo.id) {
                return orderLineItem;
              }
              return {
                ...orderLineItem,
                appliedTaxes: [...orderLineItem.appliedTaxes, appliedTax],
              };
            },
          );
        },
      );
    });

    // 3.4 OrderLineItem.taxesAmount
    orderLineItems = orderLineItems.map((orderLineItem: OrderLineItem) => ({
      ...orderLineItem,
      taxesAmount: _.sumBy(
        orderLineItem.appliedTaxes,
        (appliedTax: AppliedTax) => appliedTax.amount,
      ),
    }));

    // 3.5 Order.extraChargesAmount
    orderExtraChargesAmount = _.sumBy(
      orderLineItems,
      (orderLineItem: OrderLineItem) => orderLineItem.extraChargesAmount,
    );

    // 3.5 Order.taxesAmount
    orderTaxesAmount = _.sumBy(
      orderLineItems,
      (orderLineItem: OrderLineItem) => orderLineItem.taxesAmount,
    );

    // 5. Remaining calculated values
    // 5.1 Line item total amount (grossAmount + SUM(appliedDiscounts.amount) + SUM(exclusiveAppliedExtraCharges.amount) + SUM(exclusiveAppliedTaxes.amount)
    orderLineItems = orderLineItems.map((orderLineItem: OrderLineItem) => {
      // Only 'excluded' extra charges should contribute to saleItem.totalAmount
      const excludedExtraChargeAmount: number = _(
        orderLineItem.appliedExtraCharges,
      ).sumBy((appliedExtraCharge: AppliedExtraCharge) => {
        const orderExtraCharge: OrderExtraCharge | undefined =
          orderExtraCharges.find(
            (orderExtraCharge: OrderExtraCharge) =>
              orderExtraCharge.id === appliedExtraCharge.orderExtraChargeId,
          );
        if (!orderExtraCharge || orderExtraCharge.isLineItemPricingInclusive) {
          return 0;
        } else {
          return appliedExtraCharge.amount;
        }
      });
      // Only 'excluded' taxes should contribute to saleItem.totalAmount
      const excludedTaxesAmount: number = _(orderLineItem.appliedTaxes).sumBy(
        (appliedTax: AppliedTax) => {
          const orderTax: OrderTax | undefined = orderTaxes.find(
            (orderTax: OrderTax) => orderTax.id === appliedTax.orderTaxId,
          );
          if (!orderTax || orderTax.isLineItemPricingInclusive) {
            return 0;
          } else {
            return appliedTax.amount;
          }
        },
      );
      return {
        ...orderLineItem,
        totalAmount:
          orderLineItem.grossAmount +
          orderLineItem.discountsAmount +
          excludedExtraChargeAmount +
          excludedTaxesAmount,
      };
    });
    orderCashRoundingAmount = _(orderTenders)
      .filter(
        (orderTender: OrderTender): orderTender is OrderCashTender =>
          orderTender.type === OrderTenderType.CASH,
      )
      .sumBy(
        (orderCashTender: OrderCashTender) =>
          orderCashTender.cashDetails.roundingAmount,
      );
    // 5.2 Order.totalAmount (Amount to be collected by seller)
    orderTotalAmount =
      _.sumBy(
        orderLineItems,
        (orderLineItem: OrderLineItem) => orderLineItem.totalAmount,
      ) + orderCashRoundingAmount;
    // 5.3 Order.subtotalAmount (grossAmount + sum(item discounts amount))
    orderSubtotalAmount = orderGrossAmount + totalLineItemDiscounts;
    // 5.4 Order.grossSalesAmount (order.totalAmount - inclusive taxes & extra charges)
    orderGrossSalesAmount =
      orderGrossAmount -
      _(orderExtraCharges)
        .filter(
          (orderExtraCharge: OrderExtraCharge) =>
            orderExtraCharge.isLineItemPricingInclusive,
        )
        .sumBy(
          (orderExtraCharge: OrderExtraCharge) => orderExtraCharge.amount,
        ) -
      _(orderTaxes)
        .filter((orderTax: OrderTax) => orderTax.isLineItemPricingInclusive)
        .sumBy((orderTax: OrderTax) => orderTax.amount);
    // 5.3 Order.excessCollectionAmount
    orderExcessCollectionAmount =
      calculateTotalTenderedAmount(order) - orderTotalAmount;

    // 5.5 orderDueAmount
    // Note: we use orderTotalAmount >=0 and <0 checks because in the future we may have 'exchanges' that cause 'returns' have >0 orderTotalAmount
    if (orderTotalAmount >= 0) {
      orderDueAmount = Math.max(
        orderTotalAmount - calculateTotalTenderedAmount(order),
        0,
      );
    } else {
      orderDueAmount = Math.min(
        orderTotalAmount - calculateTotalTenderedAmount(order),
        0,
      );
    }

    const resOrder: TOrder = {
      ...order,
      lineItems: orderLineItems,
      discounts: orderDiscounts,
      extraCharges: orderExtraCharges,
      taxes: orderTaxes,
      grossAmount: orderGrossAmount,
      discountsAmount: orderDiscountsAmount,
      extraChargesAmount: orderExtraChargesAmount,
      taxesAmount: orderTaxesAmount,
      cashRoundingAmount: orderCashRoundingAmount,
      excessCollectionAmount: orderExcessCollectionAmount,
      totalAmount: orderTotalAmount,
      subtotalAmount: orderSubtotalAmount,
      grossSalesAmount: orderGrossSalesAmount,
      dueAmount: orderDueAmount,
    };
    return tryCompleteOrder(resOrder); // Complete the order if all pre-conditions are met
  };

  /**
   * Private method, sets the order status to COMPLETED and sets the closedAt timing if all the pre-conditions are met
   * For an Order to be considered completed, it must have
   * 1. 1 or more line items
   * 2. 1 or more tenders
   * 3. order.dueAmount === 0
   */
  const tryCompleteOrder = <TOrder extends Order>(order: TOrder): TOrder => {
    if (
      order.status === OrderState.OPEN &&
      order.lineItems.length > 0 &&
      order.tenders.length > 0 &&
      order.dueAmount === 0
    ) {
      // Order is actually COMPLETED
      return {
        ...order,
        status: OrderState.COMPLETED,
        updatedAt: new Date().toISOString(),
        closedAt: order.closedAt ?? new Date().toISOString(),
      };
    }

    return order;
  };

  const trySetAutomaticCustomerTicket = <TOrder extends Order>(
    order: TOrder,
  ): TOrder => {
    // Set default ticketType as SALE_NUMBER_LAST_2
    let orderTicketType: Common.TicketType | null = order.ticketType;
    if (!orderTicketType) {
      orderTicketType = Common.TicketType.SALE_NUMBER_LAST_2;
    }

    // Set default ticketName
    const orderTicketName: string = order.ticketName ?? 'Queue No.';

    // Set automatic ticket value upon Order completion
    let orderTicketValue: string | null = null;
    switch (orderTicketType) {
      case Common.TicketType.SALE_NUMBER_LAST_2: {
        orderTicketValue = order.saleNumber
          ? order.saleNumber.toString().slice(-2)
          : null;
        break;
      }
      case Common.TicketType.SALE_NUMBER_LAST_3: {
        orderTicketValue = order.saleNumber
          ? order.saleNumber.toString().slice(-3)
          : null;
        break;
      }
      default:
        orderTicketValue = order.ticketValue;
        break;
    }

    return {
      ...order,
      ticketType: orderTicketType,
      ticketName: orderTicketName,
      ticketValue: orderTicketValue,
    };
  };

  const calculateFulfillmentState = <TOrder extends Order>(
    order: TOrder,
  ): TOrder => {
    // User explicitly cancelled all fulfillments for this order
    if (order.fulfillmentState === Order_FulfillmentState.CANCELLED) {
      return order;
    }

    let fulfillmentState: Order_FulfillmentState = order.fulfillmentState;
    // TODO: Temp migration code, order.fulfillments should always exist
    if (!order.fulfillments || order.fulfillments.length === 0) {
      // Order has no fulfillments, fulfillmentState is always NONE
      fulfillmentState = Order_FulfillmentState.NONE;
    } else {
      const allFulfillmentsAreCompleted: boolean = !order.fulfillments.find(
        (orderFulfillment: OrderFulfillment) =>
          orderFulfillment.state !== OrderFulfillmentState.COMPLETED &&
          orderFulfillment.state !== OrderFulfillmentState.CANCELLED &&
          orderFulfillment.state !== OrderFulfillmentState.FAILED,
      );
      if (allFulfillmentsAreCompleted) {
        fulfillmentState = Order_FulfillmentState.COMPLETED;
      } else {
        fulfillmentState = Order_FulfillmentState.OPEN;
      }
    }

    return {
      ...order,
      fulfillmentState: fulfillmentState,
    };
  };

  const lineItemHasFulfillments = (
    order: Order,
    args: { lineItemId: string },
  ): boolean => {
    return !!_.find(
      order.fulfillments,
      (orderFulfillment: OrderFulfillment) =>
        orderFulfillment.shouldApplyToAllLineItems ||
        !!orderFulfillment.applyToLineItemIds.find(
          (lineItemId) => lineItemId === args.lineItemId,
        ),
    );
  };

  /**
   * Math.round(0.5) -> 1, Math.round(-0.5) = 0
   * round(0.5) -> 1, round(-0.5) = -1
   * https://stackoverflow.com/questions/41586838/rounding-of-negative-numbers-in-javascript
   */
  const round = (num: number): number => {
    return Math.sign(num) * Math.round(Math.abs(num));
  };

  /**
   * STATIC METHODS
   */

  export const isTerminalState = (order: Order): boolean => {
    if (
      order.status === OrderState.COMPLETED ||
      order.status === OrderState.VOIDED
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * The only terminal fulfillmentState is CANCELLED
   * Fulfillments still can be added to an order whos fulfillmentState is COMPLETED -- The fulfillmentState will just flip to OPEN again
   */
  export const isTerminalFulfillmentState = (order: Order): boolean => {
    if (order.fulfillmentState === Order_FulfillmentState.CANCELLED) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Helper method to generate a referenceId for an Order
   * Waffle's reference Ids are alphanumeric strings [a-zA-z0-9] of length 8
   */
  export const generateReferenceId = (): string => {
    let result: string = '';
    const NUM_CHARACTERS: number = 8;
    const ALLOWED_CHARACTERS: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i <= NUM_CHARACTERS; i++) {
      result += ALLOWED_CHARACTERS.charAt(
        Math.floor(Math.random() * ALLOWED_CHARACTERS.length),
      );
    }

    return result;
  };

  export const calculateTotalTenderedAmount = (order: Order): number => {
    return _.sumBy(
      _.values(order.tenders),
      (orderTender: OrderTender) => orderTender.amount,
    );
  };

  export const calculateLineItemsQuantity = (order: SaleOrder): number => {
    return _(order.lineItems).sumBy((orderLineItem) => orderLineItem.quantity);
  };
}

/**
 * EVENTS
 */

export type OrderCreatedEvent = z.infer<typeof OrderCreatedEvent.schema>;
export namespace OrderCreatedEvent {
  export const TYPE = 'orders.order.created';
  export const CURRENT_VERSION = '2022-11-14';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(TYPE).default(TYPE),
    version: z.string().default(CURRENT_VERSION),
    payload: z.object({
      order: Order.schema,
    }),
  });
  export const create = ({ order }: { order: Order }): OrderCreatedEvent => {
    return schema.parse({
      payload: {
        order: order,
      },
    });
  };
}

export type OrderUpdatedEvent = z.infer<typeof OrderUpdatedEvent.schema>;
export namespace OrderUpdatedEvent {
  export const TYPE = 'orders.order.updated';
  export const CURRENT_VERSION = '2022-11-14';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(TYPE).default(TYPE),
    version: z.string().default(CURRENT_VERSION),
    payload: z.object({
      order: Order.schema,
    }),
  });
  export const create = ({ order }: { order: Order }): OrderUpdatedEvent => {
    return schema.parse({
      payload: {
        order: order,
      },
    });
  };
}

export type OrderCompletedEvent = z.infer<typeof OrderCompletedEvent.schema>;
export namespace OrderCompletedEvent {
  export const TYPE = 'orders.order.completed';
  export const CURRENT_VERSION = '2022-11-14';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(TYPE).default(TYPE),
    version: z.string().default(CURRENT_VERSION),
    payload: z.object({
      order: Order.schema,
    }),
  });
  export const create = ({ order }: { order: Order }): OrderCompletedEvent => {
    return schema.parse({
      payload: {
        order: order,
      },
    });
  };
}

export type OrderVoidedEvent = z.infer<typeof OrderVoidedEvent.schema>;
export namespace OrderVoidedEvent {
  export const TYPE = 'orders.order.voided';
  export const CURRENT_VERSION = '2022-11-14';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(TYPE).default(TYPE),
    version: z.string().default(CURRENT_VERSION),
    payload: z.object({
      order: Order.schema,
    }),
  });
  export const create = ({ order }: { order: Order }): OrderVoidedEvent => {
    return schema.parse({
      payload: {
        order: order,
      },
    });
  };
}

export type CompletedOrderCustomerUpdatedEvent = z.infer<
  typeof CompletedOrderCustomerUpdatedEvent.schema
>;
export namespace CompletedOrderCustomerUpdatedEvent {
  export const TYPE = 'orders.order.completed_order_customer_updated';
  export const CURRENT_VERSION = '2022-11-14';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(TYPE).default(TYPE),
    version: z.string().default(CURRENT_VERSION),
    payload: z.object({
      order: Order.schema,
    }),
  });
  export const create = ({
    order,
  }: {
    order: Order;
  }): CompletedOrderCustomerUpdatedEvent => {
    return schema.parse({
      payload: {
        order: order,
      },
    });
  };
}
