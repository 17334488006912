import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import * as Common from '../common';
import * as Sellers from '../sellers';

export interface IGtoHandler {
  // db: IDatabase<any>;
  // cronConfig: GtoCronConfig;

  handle(): void;
  // generateFile(): Promise<void>; // Generates the GTO file and returns the path to the generated file
  // sendFile(): void; // Takes the GTO File and sends it to the mall
  // backupFile(): void; // Backup generated file in S3
  // updateCronConfig(): void;
  // cleanUp(): void;
}

/**
 *  Top level Interface for GtoCronConfig object. Consist of fields from gto_cron_config table, and nested GtoConfigJson for specific fields
 * */
// export interface GtoCronConfig {
//   gtoCronConfigId: string;
//   type: GtoCronConfigType;
//   sellerId: string;
//   sellerLocationId: string;
//   isEnabled: boolean;
//   json: Record<string,any>;
// }

/**
 * Note:
 * We group the GtoHandlers by the expected file output format
 * Eg. FRASERS__SYNTHESIS may have some malls that don't belong to Frasers but we just name it under Frasers because
 *     it was the first mall management to use this format or is the most dominant mall management
 */

export enum GtoCronConfigType {
  /**
   * Note: These GTO files are Sent per sale
   * ARC (Alexandra Retail Centre)
   * VivoCity
   */
  MAPLETREE__SYNTHESIS = 'MAPLETREE__SYNTHESIS',

  /**
   * H<machineId>_<yyyyMMdd>.txt, 1 line per hour, 18 fields
   * Change Alley Mall
   * Changi City Point
   * Hillion Mall
   * JTC
   * Marina One
   * Northpoint City
   * Tampines One
   */
  FRASERS__SYNTHESIS = 'FRASERS__SYNTHESIS',

  FRASERS_AZURE__SYNTHESIS = 'FRASERS_AZURE__SYNTHESIS',

  /**
   * D<machineId>.<fileSerialNumber>
   * Raffles City
   * Tampines Mall
   * ION Orchard
   */
  CAPITALAND__SYNTHESIS = 'CAPITALAND__SYNTHESIS',

  /**
   * File format similar to FRASERS__SYNTHESIS
   * But FTP server format similar to CAPITALAND__SYNTHESIS
   * TODO: Find a way to separate out config for file generation vs file sending
   *
   * Dark Gallery at Funan
   */
  FRASERS_CAPITALAND__SYNTHESIS = 'FRASERS_CAPITALAND__SYNTHESIS',

  /**
   * <machineId>_<yyyyMMdd_HHmmss>.txt, 1 line per hour, 9 fields
   * One Raffles Place
   * Orchard Gateway
   * Jem
   * Wisma Atria
   */
  OUBC__SYNTHESIS = 'OUBC__SYNTHESIS',

  OUBC_CAPITALAND__SYNTHESIS = 'OUBC_CAPITALAND__SYNTHESIS',

  /**
   * <yyMMdd>.txt, 1 line per hour,9 lines
   * Note: Similar formatting to OUBC__SYNTHESIS but with different file naming (M = Mercatus?)
   * AMK Hub
   * Jurong Point
   */
  M_MALLS__SYNTHESIS = 'M_MALLS__SYNTHESIS',

  /**
   * <machineId>_<yyyyMMdd_HHmmss>.txt, 1 line for entire day, 9 fields
   * Nex
   */
  MERCATUS__SYNTHESIS = 'MERCATUS__SYNTHESIS',

  /**
   * CDL01<machineId>_<yyyyMMdd_HHmmss>.txt, 1 line for entire day, 9 fields
   */
  CDL__SYNTHESIS = 'CDL__SYNTHESIS',

  /**
   * <machineId>_<yyyyMMdd_HHmmss>.txt
   * Kallang Wave Mall
   */
  SMRT__SYNTHESIS = 'SMRT__SYNTHESIS',

  /**
   * Note: 1 sale = 1 line
   * Buangkok Square
   * North Shore Plaza 1
   * Oasis Terraces
   * Pioneer Mall
   * Canberra Plaza
   */
  HDB__EUROSTOP = 'HDB__EUROSTOP',

  /**
   * <mallCode><tenantCode><yymmdd>.csv, 1 line per day for the past 30 days, 5 fields per line
   * Changi City Point (There is a different CCP GTO integration with Synthesis)
   */
  FRASERS__EUROSTOP = 'FRASERS__EUROSTOP',

  /**
   * TD_<YYYYMMDD>.txt, 1 line, 6 fields
   * Novena Square2
   * Clarke Quay Central
   * Marina Square
   */
  FAR_EAST__DTS = 'FAR_EAST__DTS',

  FAR_EAST_TAX__DTS = 'FAR_EAST_TAX__DTS',

  // Basically like Fraser but with Col 12 (Touch n Go) default to 0
  ASIAONE__EUROSTOP = 'ASIAONE__EUROSTOP',

  TROPICANA__SYNTHESIS = 'TROPICANA__SYNTHESIS',
  // Wct Mall
  MALLS__WCT = 'MALLS__WCT',

  PARADIGM_MALLS_JOHOR_BAHRU__WCT = 'PARADIGM_MALLS_JOHOR_BAHRU__WCT',
}

export enum GtoServerType {
  FTP = 'FTP',
  SFTP = 'SFTP',
}

// MIGRATION TO ZOD
export type GtoFileGeneration = z.infer<typeof GtoFileGeneration.schema>;

export namespace GtoFileGeneration {
  export const schema = z.object({
    gtoCronConfigId: z.string().uuid().nullish(),
    type: z.nativeEnum(GtoCronConfigType).nullish(),
    sellerId: z.string().uuid().nullish(),
    sellerLocationId: z.string().nullish(),
    isEnabled: z.boolean().nullish(),
    json: z.any().nullish(),
    misc: z.any().nullish(),
  });
}

export type GtoFtp = z.infer<typeof GtoFtp.schema>;

export namespace GtoFtp {
  export const schema = z.object({
    ftpHost: z.string().nullish(),
    ftpPort: z.number().nullish(),
    ftpUsername: z.string().nullish(),
    ftpPassword: z.string().nullish(),
    ftpFolderPath: z.string().nullish(),
    uploadType: z.nativeEnum(GtoServerType).nullish(),
  });
}

export type GtoCronConfig = z.infer<typeof GtoCronConfig.schema>;
export namespace GtoCronConfig {
  export const schema = z.object({
    gtoCronConfigId: z.string().uuid(),
    sellerName: z.string(),
    sellerLocationName: z.string(),
    type: z.nativeEnum(GtoCronConfigType),
    sellerId: z.string().uuid(),
    sellerLocationId: z.string(),
    isEnabled: z.boolean(),
    json: z.any(),
    ftp: GtoFtp.schema.nullish(),
    fileGeneration: GtoFileGeneration.schema.nullish(),
  });

  export const create = (args: Partial<GtoCronConfig>): GtoCronConfig => {
    return schema.parse({
      ...args,
    });
  };
}

export interface MapletreeSynthesisGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.MAPLETREE__SYNTHESIS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    machineId: string; // Issued by mall
    ftpHost: string;
    ftpPassword: string;
  };
}

export interface FrasersSynthesisGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.FRASERS__SYNTHESIS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    machineId: string; // Issued by mall
    ftpHost: string;
    ftpPassword: string;
    batchId: number; // Running number for this location, new batchId everyday
    isGstRegistered: boolean; // On the report, Y if true N if false
  };
}

export interface FrasersAzureSynthesisGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.FRASERS_AZURE__SYNTHESIS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    machineId: string; // Issued by mall
    ftpHost: string;
    ftpUsername: string;
    ftpPassword: string;
    batchId: number; // Running number for this location, new batchId everyday
    isGstRegistered: boolean; // On the report, Y if true N if false
  };
}

export interface OubcSynthesisGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.OUBC__SYNTHESIS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    machineId: string; // Issued by mall
    ftpHost: string;
    ftpPassword: string;
    batchId: number; // Running number for this location, new batchId everyday
  };
}

export interface OubcCapitalandSynthesisGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.OUBC_CAPITALAND__SYNTHESIS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    machineId: string;
    ftpHost: string;
    ftpUser: string;
    ftpPort: number;
    ftpPassword: string;
    folderPath: string;
    fileSerialNumber: number;
    batchId: number; // Running number for this location, new batchId everyday
  };
}

export interface SmrtSynthesisGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.SMRT__SYNTHESIS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    machineId: string; // Issued by mall
    ftpHost: string;
    ftpPassword: string;
    batchId: number; // Running number for this location, new batchId everyday
  };
}

// Format is similar to OrchardGateway GTO but with different formatting of the file name
export interface MMallsSynthesisGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.M_MALLS__SYNTHESIS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    machineId: string; // Issued by mall
    ftpHost: string;
    ftpPassword: string;
    batchId: number; // Running number for this location, new batchId everyday
  };
}

// Oasis Terraces
export interface HdbEurostopGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.HDB__EUROSTOP;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    mallCode: string;
    tenantCode: string;
    tillNumber: number;
    ftpHost: string;
    ftpUsername: string;
    ftpPassword: string;
  };
}

export interface FrasersEurostopGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.FRASERS__EUROSTOP;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    mallCode: string;
    tenantCode: string;
    tillNumber: number;
    ftpHost: string;
    ftpUsername: string;
    ftpPassword: string;
  };
}

export interface AsiaOneEurostopGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.ASIAONE__EUROSTOP;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    mallCode: string;
    tenantCode: string;
    tillNumber: number;
    ftpHost: string;
    ftpUsername: string;
    ftpPassword: string;
  };
}

export interface FarEastDtsGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.FAR_EAST__DTS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    clientId: string;
    ftpHost: string;
    ftpPassword: string;
  };
}

export interface FarEastTaxDtsGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.FAR_EAST_TAX__DTS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    clientId: string;
    ftpHost: string;
    ftpPassword: string;
  };
}

export interface CapitalandSynthesisGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.CAPITALAND__SYNTHESIS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType.SFTP;
    machineId: string;
    ftpHost: string;
    ftpUser: string;
    ftpPort: number;
    ftpPassword: string;
    folderPath: string;
    fileSerialNumber: number;
  };
}

export interface MercatusSynthesisGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.MERCATUS__SYNTHESIS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType.FTP;
    machineId: string; // Issued by mall
    ftpHost: string;
    ftpPassword: string;
    batchId: number; // Running number for this location, new batchId everyday
  };
}

export interface CDLSynthesisGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.CDL__SYNTHESIS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType.FTP;
    machineId: string; // Issued by mall
    assetId: string; // Issued by mall
    ftpHost: string;
    ftpPassword: string;
    batchId: number; // Running number for this location, new batchId everyday
  };
}

export interface MallsWctGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.MALLS__WCT;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    locationCode: string;
    tenantCode: string;
    logs: any;
    ftpHost: string;
    ftpUsername: string;
    ftpPassword: string;
  };
}

export interface ParadigmMallsJohorBahruGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.PARADIGM_MALLS_JOHOR_BAHRU__WCT;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    locationCode: string;
    tenantCode: string;
    logs: any;
    ftpHost: string;
    ftpUsername: string;
    ftpPassword: string;
  };
}

// Event States for publishing Messages
// GTO
// export interface GenerateGtoCommand {
//   id: string;
//   type: typeof GenerateGtoCommand.TYPE;
//   version: typeof GenerateGtoCommand.CURRENT_VERSION;
//   createdAt: string;
//   payload: {
//     gtoCronConfig: GtoCronConfig;
//     sellerName: string;
//     sellerLocationName: string;
//   };
// }

// type EventPayload = {
//   name: string;
//   data: Record<string, any>;
//   user?: Record<string, any>;
//   id?: string;
//   ts?: number;
//   v?: string;
// }

export interface TropicanaSynthesisGtoCronConfig extends GtoCronConfig {
  gtoCronConfigId: string;
  type: GtoCronConfigType.TROPICANA__SYNTHESIS;
  sellerId: string;
  sellerLocationId: string;
  isEnabled: boolean;
  json: {
    serverType: GtoServerType;
    machineId: string; // Issued by mall
    ftpHost: string;
    ftpPassword: string;
    batchId: number; // Running number for this location, new batchId everyday
    isGstRegistered: boolean; // On the report, Y if true N if false
  };
}

// export namespace GenerateGtoCommand {
//   export const TYPE = 'gtos.generate_gto_command';
//   export const CURRENT_VERSION = '2022-01-28';

//   export const create = ({
//     gtoCronConfig,
//     sellerName,
//     sellerLocationName,
//   }: {
//     gtoCronConfig: GtoCronConfig;
//     sellerName: string;
//     sellerLocationName: string;
//   }): GenerateGtoCommand => {
//     return {
//       id: uuidv4(),
//       type: TYPE,
//       version: CURRENT_VERSION,
//       createdAt: DateTime.local().setZone('Asia/Singapore').toString(),
//       payload: {
//         gtoCronConfig: gtoCronConfig,
//         sellerName: sellerName,
//         sellerLocationName: sellerLocationName,
//       },
//     };
//   };
// }
