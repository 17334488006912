/**
 * Custom component created by Zames
 * This component is probably not that accessible yet, TODO for next time
 *
 * Usage:
 * <HorizontalNav orientation={'horizontal'}>
 *   <Link to={'/settings/team/team-members'}>
 *     <HorizontalNavButton
 *       active={location.pathname.includes(
 *         '/settings/team/team-members',
 *       )}>
 *       Team Members
 *     </HorizontalNavButton>
 *   </Link>
 * </HorizontalNav>
 */
import React from 'react';

import { cn } from '../../lib/utils';
import { Button, ButtonProps } from './button';
import { Separator } from './separator';

const HorizontalNav = React.forwardRef<HTMLDivElement>(
  ({ orientation = 'horizontal', className, ...props }, ref) => {
    return (
      <>
        <div
          ref={ref}
          className={cn('-ml-2 flex flex-row gap-1', className)}
          {...props}
        />
        <Separator />
      </>
    );
  },
);

HorizontalNav.displayName = 'HorizontalNav';

interface HorizontalNavButtonProps extends Omit<ButtonProps, 'variant'> {
  isActive?: boolean;
}

const HorizontalNavButton = React.forwardRef<
  HTMLButtonElement,
  HorizontalNavButtonProps
>(({ className, isActive, children, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      variant="ghost"
      className={cn(
        'w-full items-center justify-start p-2',
        isActive
          ? 'text-flair hover:text-flair'
          : 'text-muted-foreground hover:text-foreground',
        className,
      )}
      {...props}>
      <span className="relative flex flex-col">
        {children}
        {/* The underline indicator */}
        {isActive && (
          <span className="bg-flair absolute -bottom-[10px] h-0.5 w-full rounded-full" />
        )}
      </span>
    </Button>
  );
});

HorizontalNavButton.displayName = 'HorizontalNavButton';

export { HorizontalNav, HorizontalNavButton };
