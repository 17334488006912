/**
 * Toast component from sonner
 *
 * Edits
 * 1. Portal to render Toaster in document.body instead (so it shows up on top of Dialogs
 */
import { useTheme } from 'next-themes';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { Toaster as Sonner, toast } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme();

  const toasterContent = (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-muted-foreground',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
        },
      }}
      {...props}
    />
  );
  return createPortal(toasterContent, document.body);
};

/**
 * Light wrapper around Sonner to wrap it around a useToast hook, and to make it take in a configuration object instead
 *
 *
 * Usage
 * const toast = useToast();
 * toast.show({
 *   status: 'success',
 *   title: 'Something happened',
 *   description: 'More information about the event',
 * });
 */
const useToast = () => {
  return {
    show: (
      args: Parameters<typeof toast>[1] & { title: string } & {
        status: 'success' | 'error';
      },
    ) => {
      switch (args.status) {
        case 'success':
          return toast(args.title, args);
        case 'error':
          return toast.error(args.title, args);
        default:
          return toast(args.title, args);
      }
    },
  };
};

export { Toaster, useToast };
